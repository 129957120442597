import { Injectable } from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService extends BaseComponent {
  private toastSubject: BehaviorSubject<IToast | null> =
    new BehaviorSubject<IToast | null>(null);
  private historicToasts: IToast[] = [];
  private toastDuration: number = 5000;
  constructor() {
    super();
  }

  public get toast$(): Observable<IToast | null> {
    return this.toastSubject.asObservable();
  }

  public showToast(
    message: string,
    type: ToastType,
    duration: number = this.toastDuration
  ): void {
    const toast = {
      message: message,
      type: type,
      duration: duration,
    } as IToast;

    this.historicToasts.push(toast);
    this.toastSubject.next(toast);
  }

  public hideToast(): void {
    this.toastSubject.next(null);
  }
}

export enum ToastType {
  info,
  success,
  warning,
  danger,
}

export interface IToast {
  message: string;
  type: ToastType;
  duration: number;
}
