import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from 'shared/headers';
import { DomService } from './dom.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public readonly ios: boolean = false;
  public readonly android: boolean = false;
  public readonly isStandalone: boolean = false;
  public readonly isFacebook: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private domService: DomService
  ) {
    if (this.isServer) {
      return;
    }
    const userAgent = this.domService.navigator?.userAgent ?? '';
    this.ios =
      /(iPad|iPhone|iPod)/gi.test(userAgent) ||
      (navigator.userAgent.includes('Mac') &&
        this.isBrowser &&
        !!this.domService.document.ontouchend);
    this.android = /android/gi.test(userAgent);
    this.isStandalone =
      this.domService.window?.matchMedia('(display-mode: standalone)')
        .matches ||
      (this.domService.navigator as any)?.standalone ||
      this.domService.document.referrer.includes('android-app://');
    this.isFacebook = /(FBAN|FBAV)/gi.test(userAgent);
  }

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public get platform(): Platform {
    return this.isBrowser ? Platform.Browser : Platform.Server;
  }
}
