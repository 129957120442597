import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { BaseComponent } from '@standalone/base-component.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService
  extends BaseComponent
  implements IErrorService
{
  constructor(private loggingService: LoggingService) {
    super();
  }

  handleError(error: Error) {
    this.loggingService.logException(error);
  }
}
