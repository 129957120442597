import { Injectable } from '@angular/core';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { environment } from '@environment/environment';
import {
  AppInsightsService,
  EventType,
  IEventTelemetry,
  ITraceTelemetry,
  SeverityLevel,
} from './app-insights.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BaseComponent } from '@standalone/base-component.component';

@Injectable({
  providedIn: 'root',
})
export class LoggingService extends BaseComponent {
  constructor(private appInsightsService: AppInsightsService) {
    super();
    if (environment.logging.consoleEnabled) {
      console.log('Console logging enabled!');
    }
  }

  public log(
    message: string,
    severityLevel?: SeverityLevel,
    properties?: ICustomProperties | any
  ): void {
    const logObject = { message, properties };
    this.logConsole(logObject);

    if (!environment.logging.enabled) {
      return;
    }
    const traceTelemetry = {
      message: message,
      severityLevel: severityLevel,
      properties: properties
        ? JSON.parse(JSON.stringify(properties))
        : properties,
    } as ITraceTelemetry;
    this.appInsightsService.logTrace(traceTelemetry);
  }

  public logException(error: Error | HttpErrorResponse): void {
    if (environment.logging.consoleEnabled) {
      console.error(error);
    }
    if (environment.logging.enabled) {
      this.appInsightsService.logException({ exception: error });
    }
  }

  private logConsole(obj: any) {
    if (environment.logging.consoleEnabled) {
      console.log(obj);
    }
  }

  public logEvent(
    eventName: string,
    eventType: EventType,
    properties?: any
  ): void {
    const eventTelemetry = {
      name: eventName,
      type: eventType,
      properties: properties
        ? JSON.parse(JSON.stringify(properties))
        : properties,
    } as IEventTelemetry;

    this.logConsole(eventTelemetry);
    if (environment.logging.enabled) {
      this.appInsightsService.logEvent(eventTelemetry);
    }
  }
}

export {
  IEventTelemetry,
  ITraceTelemetry,
  EventType,
  ICustomProperties,
  SeverityLevel,
} from './app-insights.service';
