import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, takeUntil } from 'rxjs';
import {
  IFotverketPolicy,
  IShopifyPolicy,
} from 'server/interfaces/shopify-interface';
import { BaseApiClientService } from '@shared/services/base-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends BaseApiClientService {
  private policySubject: BehaviorSubject<IFotverketPolicy[]> =
    new BehaviorSubject<IFotverketPolicy[]>([]);
  private policies: IFotverketPolicy[] = [];
  constructor() {
    super('policies');
    const icons = [
      'fa-user',
      'fa-tags',
      'fa-user-secret',
      'fa-box-open',
      'fa-dolly',
      'fa-shop-lock',
    ];
    this.policies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (policies) => {
        this.policies = policies;
      },
    });
    this.get<IShopifyPolicy[]>()
      .pipe(
        map((policies) => {
          const fotverketPolicies = [];
          for (let index = 0; index < policies.length; index++) {
            const policy = policies[index];
            fotverketPolicies.push({
              ...policy,
              icon: icons[index],
            } as IFotverketPolicy);
          }
          return fotverketPolicies;
        })
      )
      .subscribe({
        next: (policies) => {
          this.policySubject.next(policies);
          this.readySubject.next(true);
        },
      });
  }

  public get policies$(): Observable<IFotverketPolicy[]> {
    return this.policySubject.asObservable();
  }

  public getPolicyByHandle(handle: string): IFotverketPolicy | undefined {
    return this.policies.find((policy) => policy.handle === handle);
  }
}
