import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  APP_INITIALIZER,
  isDevMode,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from '@features/autocomplete/autocomplete.module';
import { ButtonModule } from '@features/button/button.module';
import { ModalModule } from '@modal/modal.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { PoliciesComponent } from './components/footer/policies/policies.component';
import { HeaderComponent } from './components/header/header.component';
import { ProductAutocompleteComponent } from './components/header/product-autocomplete/product-autocomplete.component';
import { MainComponent } from './components/main-component/main-component.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { CoreRoutingModule } from './core-routing.module';
import { ToCheckoutComponent } from './components/to-checkout/to-checkout.component';
import { ShoppingCartModule } from '@features/shopping-cart/shopping-cart.module';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { HttpErrorInterceptorService } from '@core/services/http-interceptor.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { API_URL_V1, apiV1Url } from 'server/constants';
import { LoadingModule } from '@features/loading/loading.module';
import '@angular/common/locales/global/se-SE';
import { ToastComponent } from './components/toast/toast.component';
import { DataCyDirective } from '@standalone/directives/data-cy.directive';
import { StructuredDataModule } from '@features/structured-data/structured-data.module';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { MatIconModule } from '@angular/material/icon';
import { SkeletonComponent } from '@standalone/components/skeleton/skeleton.component';
import { AppInsightsService } from './services/app-insights.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@environment/environment';
import { Observable, of } from 'rxjs';
import { TranslationLoader } from 'shared/TranslationLoader';
import { DomService } from './services/dom.service';
import { CookieService } from './services/cookie.service';
import { IFotverketCookie } from './services/cookie-consent.service';
import { CustomTranslateService } from './services/custom-translate.service';
import { LanguageComponent } from './components/language/language.component';
import { PlatformService } from './services/platform.service';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    BreadcrumbComponent,
    PageContentComponent,
    FooterComponent,
    PoliciesComponent,
    ProductAutocompleteComponent,
    ToCheckoutComponent,
    ToastComponent,
    CookieConsentComponent,
    LanguageComponent,
  ],
  imports: [
    BrowserModule,
    CoreRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule,
    AutocompleteModule,
    ButtonModule,
    StructuredDataModule,
    ShoppingCartModule,
    LoadingModule,
    DataCyDirective,
    MatIconModule,
    SkeletonComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'sv',
      loader: {
        provide: TranslateLoader,
        useFactory: (
          http: HttpBackend,
          state: TransferState,
          dom: DomService,
          cookie: CookieService,
          plat: PlatformService
        ) => new CustomTranslateLoader(http, state, dom, cookie, plat),
        deps: [
          HttpBackend,
          TransferState,
          DomService,
          CookieService,
          PlatformService,
        ],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideClientHydration(
      withHttpTransferCacheOptions({ includePostRequests: true })
    ),
    {
      provide: LOCALE_ID,
      useValue: 'se-SE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'SEK',
    },
    {
      provide: API_URL_V1,
      useValue: apiV1Url,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AppInsightsService],
      useFactory: appInitializerFactory,
      multi: true,
    },
    AppInsightsService,
    provideHttpClient(withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    SsrCookieService,
    {
      provide: TranslateService,
      useExisting: CustomTranslateService,
    },
  ],
  bootstrap: [MainComponent],
})
export class CoreModule {}

export function appInitializerFactory(appinsights: AppInsightsService) {
  appinsights.initialize();
  return () => of(undefined);
}

class CustomTranslateLoader extends TranslationLoader {
  private loader: TranslateHttpLoader;
  constructor(
    private http: HttpBackend,
    private state: TransferState,
    private domService: DomService,
    private cookieService: CookieService,
    private platformService: PlatformService
  ) {
    super();
    this.loader = new TranslateHttpLoader(
      new HttpClient(this.http),
      `${environment.apiTarget}/assets/i18n/`,
      `.json`
    );
  }
  public getTranslation(lang: string): Observable<any> {
    const cookie = this.cookieService.tryGet<IFotverketCookie>(
      environment.cookies.cookieName
    );
    if (cookie?.language) {
      const key = makeStateKey<any>(`translation-${cookie.language}`);
      const data = this.state.get(key, null);
      if (data) {
        this.domService.document.documentElement.lang = cookie.language;
        return of(data);
      }
    }

    this.domService.document.documentElement.lang = cookie?.language ?? lang;
    return this.loader.getTranslation(cookie?.language ?? lang);
  }
}
