<popup-modal #consentModal>
  <form [formGroup]="cookieForm" class="needs-validation cookie-form">
    <div class="form-row flex center">
      <div class="col cookie-container">
        <h2 style="margin: auto; width: fit-content">
          {{ "COOKIES.CONSENT.HEADER" | translate }}
        </h2>
        <hr />
        <div class="form-group">
          <div class="flex space">
            <div class="custom-control custom-switch">
              <input
                title="{{ 'COOKIES.CONSENT.NECESARY.INFO' | translate }}"
                type="checkbox"
                class="custom-control-input"
                id="necessaryCookie"
                checked="true"
                disabled
              />
              <label class="custom-control-label bold" for="necessaryCookie">{{
                "COOKIES.CONSENT.NECESARY.TITLE" | translate
              }}</label>
            </div>
            <button
              title="{{ 'COOKIES.CONSENT.NECESARY.SHOW_INFO' | translate }}"
              class="icon-button"
              (click)="showNecessary = !showNecessary"
            >
              <i
                [class.fa-chevron-up]="showNecessary"
                [class.fa-chevron-down]="!showNecessary"
                class="fa-solid fa-2x is-clickable"
              ></i>
            </button>
          </div>
          @if (showNecessary) {
          <p>
            {{ "COOKIES.CONSENT.NECESARY.DESCRIPTION" | translate }}
          </p>
          }
        </div>
        <div class="form-group is-hidden">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              formControlName="functional"
              id="functionalCookie"
            />
            <label class="custom-control-label bold" for="functionalCookie">{{
              "COOKIES.CONSENT.FUNCTIONAL.TITLE" | translate
            }}</label>
          </div>
        </div>
        <div class="form-group">
          <div class="flex space">
            <div class="custom-control custom-switch">
              <input
                title="{{ 'COOKIES.CONSENT.STATISTICS.INFO' | translate }}"
                type="checkbox"
                class="custom-control-input"
                formControlName="statistical"
                id="statisticalCookie"
              />
              <label
                class="custom-control-label bold"
                for="statisticalCookie"
                >{{ "COOKIES.CONSENT.STATISTICAL.TITLE" | translate }}</label
              >
            </div>
            <button
              title="{{ 'COOKIES.CONSENT.STATISTICS.SHOW_INFO' | translate }}"
              class="icon-button"
              (click)="showStatistical = !showStatistical"
            >
              <i
                [class.fa-chevron-up]="showStatistical"
                [class.fa-chevron-down]="!showStatistical"
                class="fa-solid fa-2x is-clickable"
              ></i>
            </button>
          </div>
          @if (showStatistical) {
          <p>
            {{ "COOKIES.CONSENT.STATISTICS.DESCRIPTION" | translate }}
          </p>
          }
        </div>
        <div class="form-group is-hidden">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              formControlName="marketing"
              id="marketingCookie"
            />
            <label class="custom-control-label bold" for="marketingCookie">{{
              "COOKIES.CONSENT.MARKETING.TITLE" | translate
            }}</label>
          </div>
        </div>
        <div class="form-group flex center even" style="margin: 0px">
          <fot-button
            [dataCy]="'cookie-consent-deny-all'"
            (clicked)="setCookies(null)"
            [type]="'submit'"
            [title]="'COOKIES.CONSENT.DENY' | translate"
            [classes]="'btn btn-fotverket shadow'"
            >{{ "COMMON.DENY" | translate }}</fot-button
          >
          <fot-button
            (clicked)="setCookies(false)"
            [type]="'submit'"
            [title]="'COOKIES.CONSENT.APPROVE_SOME' | translate"
            [classes]="'btn btn-fotverket shadow'"
            >{{ "COMMON.APPROVE" | translate }}</fot-button
          >
          <fot-button
            [dataCy]="'cookie-consent-approve-all'"
            (clicked)="setCookies(true)"
            [type]="'submit'"
            [title]="'COOKIES.CONSENT.APPROVE_ALL' | translate"
            [classes]="'btn btn-fotverket shadow'"
            >{{ "COOKIES.CONSENT.ACTION.APPROVE_ALL" | translate }}</fot-button
          >
        </div>
      </div>
    </div>
  </form>
</popup-modal>

<icon-button
  [class]="'btn-cookie'"
  [title]="'COOKIES.CHANGE_SETTINGS' | translate"
  (whenClick)="show$.next(true)"
>
  <i class="fa-solid fa-cookie-bite fa-lg"></i>
</icon-button>
