import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';

import { combineLatestWith, map, takeUntil } from 'rxjs/operators';
import { NavigationService } from '@core/services/navigation.service';
import { ShoppingCartService } from '@core/services/shopping-cart.service';
import { EventType, LoggingService } from '@core/services/logging.service';

@Component({
  selector: 'core-to-checkout',
  template: ` <button
    type="button"
    class="btn-to-checkout"
    [title]="'CART.TO_CHECKOUT' | translate"
    [routerLink]="['/kassa']"
    (click)="logEvent()"
    [class.show]="show"
  >
    <i class="fa-solid fa-cash-register fa-2x"></i>
  </button>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToCheckoutComponent extends BaseComponent implements OnInit {
  protected show = false;
  constructor(
    private navigationService: NavigationService,
    private shoppingCartService: ShoppingCartService,
    private cdr: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {
    super();
  }
  ngOnInit(): void {
    this.navigationService.navigationStart$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(
          this.shoppingCartService.cartUpdated$.pipe(
            takeUntil(this.ngUnsubscribe)
          ),
          this.shoppingCartService.open$.pipe(takeUntil(this.ngUnsubscribe))
        ),
        map((v) => ({ navigationEvent: v[0], cart: v[1].cart, cartOpen: v[2] }))
      )
      .subscribe({
        next: (value) => {
          this.show =
            (!value.navigationEvent?.url?.includes('kassa') &&
              value.cart.items.length > 0 &&
              !value.cartOpen) ??
            false;
          this.cdr.detectChanges();
        },
      });
  }

  protected logEvent(): void {
    this.loggingService.logEvent('goToCheckout', EventType.click, {
      origin: 'CheckoutShortcut',
    });
  }
}
