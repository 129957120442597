import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventType, LoggingService } from '@core/services/logging.service';
import { ProductService } from '@core/services/product.service';
import {
  CartUpdated,
  ShoppingCartService,
} from '@core/services/shopping-cart.service';
import { DropdownModalComponent } from '@modal/components/dropdown-modal/dropdown-modal.component';
import { BaseComponent } from '@standalone/base-component.component';
import { first, map, merge, takeUntil } from 'rxjs';
import { IFotverketCart } from 'server/interfaces/fotverket-interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('dropdown') private dropdown!: DropdownModalComponent;
  @ViewChild('buttons') private buttons?: ElementRef<HTMLDivElement>;
  @ViewChild('wrapper') private wrapper!: ElementRef<HTMLDivElement>;
  @ViewChild('totalItemCountContainer')
  private totalItemCountContainer?: ElementRef<HTMLDivElement>;
  protected totalItemcount: number = 0;
  protected totalPrice = 0;
  protected lineItems: ICartVariant[] = [];
  protected updatingCart: boolean = false;
  protected showThing: boolean = false;
  protected lastItemAdded: string | undefined;
  private cancelAnimation?: NodeJS.Timeout;
  constructor(
    protected cartService: ShoppingCartService,
    private productService: ProductService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.dropdown.open$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (open) => {
        this.cartService.cartOpen(open);
        if (!open) {
          return;
        }
        this.onScroll();
      },
    });
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.dropdown?.displayModal(false);
      this.cdr.detectChanges();
    });

    this.productService.ready$.pipe(first((r) => r)).subscribe({
      next: (ready) => {
        if (ready) {
          this.cartService.loading$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (loading) => {
                this.updatingCart = loading?.loading ?? false;
                this.cdr.detectChanges();
              },
            });

          merge(
            this.cartService.cartUpdated$.pipe(takeUntil(this.ngUnsubscribe)),
            this.cartService.cartCreated$.pipe(
              first(),
              map((cart) => ({
                cart: cart,
                type: CartUpdated.Created,
                message: undefined,
              }))
            )
          )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (cartUpdated) => {
                this.updateCart(cartUpdated.cart);
                if (cartUpdated.type === CartUpdated.ItemAdded) {
                  if (this.cancelAnimation) {
                    clearTimeout(this.cancelAnimation);
                  }
                  const animation = 'circle';
                  this.totalItemCountContainer?.nativeElement.classList.add(
                    animation
                  );
                  this.cancelAnimation = setTimeout(() => {
                    this.totalItemCountContainer?.nativeElement.classList.remove(
                      animation
                    );
                    this.cdr.detectChanges();
                  }, 2000);
                }
                this.cdr.detectChanges();
              },
            });
        }
      },
    });
  }

  private updateCart(cart: IFotverketCart | undefined): void {
    const cartItems = this.cartService.convertToCartVariant(cart?.items ?? []);
    this.totalItemcount = cart?.totalQuantity ?? 0;
    this.totalPrice = cart?.totalPrice ?? 0;
    this.populateCart(cartItems);
  }

  private populateCart(cartLineItems: ICartVariant[]): void {
    this.lineItems = cartLineItems;
  }

  protected clearCart(): void {
    this.loggingService.logEvent('clearCart', EventType.click);
    this.cartService.clearCart();
  }

  protected onScroll() {
    const cartWrapper = this.wrapper.nativeElement;
    const scrollPosition = cartWrapper.scrollTop;
    const scrollHeight = cartWrapper.scrollHeight;
    const result = scrollHeight - scrollPosition;
    const buttons = this.buttons?.nativeElement;
    if (buttons) {
      this.dropdown.modal?.nativeElement.clientHeight === result
        ? buttons.classList.remove('custom-shadow')
        : buttons.classList.add('custom-shadow');
    }
  }
}

export interface ICartVariant {
  productId: number;
  variantId: number;
  title: string;
  quantity: number;
  imageSrc: string;
  selectedOptions: Array<{ key: string; value: string }>;
  handle: string;
  price: number;
}
