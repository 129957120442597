import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StructuredDataComponent } from './structured-data.component';

@NgModule({
  declarations: [StructuredDataComponent],
  imports: [CommonModule],
  exports: [StructuredDataComponent],
})
export class StructuredDataModule {}
