import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Renderer2,
} from '@angular/core';
import { DropdownModalComponent } from '../dropdown-modal/dropdown-modal.component';
import { PlatformService } from '@core/services/platform.service';
import { DomService } from '@core/services/dom.service';
import { NavigationService } from '@core/services/navigation.service';
import { ToastService } from '@core/services/toast.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'popup-modal',
  template: `@if (display) {
    <div
      class="popup-modal flex center center-vertical"
      [class.close]="!(open$ | async)"
      [class.open]="open$ | async"
    >
      <icon-button
        [title]="
          'COMMON.CLOSE' | translate : { text: 'COMMON.WINDOW' | translate }
        "
        [class]="'btn-close show flex center center-vertical'"
        (whenClick)="closeWithDelay()"
      >
        <i class="fa-solid fa-x fa-lg"></i>
      </icon-button>
      <ng-content></ng-content>
    </div>
    }`,
  styles: [],
})
export class PopupModalComponent extends DropdownModalComponent {
  constructor(
    renderer: Renderer2,
    toastService: ToastService,
    navigationService: NavigationService,
    domService: DomService,
    platformService: PlatformService,
    cdr: ChangeDetectorRef
  ) {
    super(
      renderer,
      toastService,
      navigationService,
      domService,
      platformService,
      cdr
    );
  }
}
