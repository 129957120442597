import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IFotverketData } from '@environment/settings-interface';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('@pages/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
    data: {
      name: 'HOME.NAME',
      title: 'HOME.TITLE',
      description: 'HOME.DESCRIPTION',
    },
  },
  {
    path: 'behandlingar',
    loadChildren: () =>
      import('@pages/services/services.module').then((m) => m.ServicesModule),
    data: {
      name: 'TREATMENTS.TITLE',
      title: 'TREATMENTS.TITLE',
      description: 'TREATMENTS.DESCRIPTION',
    },
  },
  {
    path: 'boka',
    redirectTo: 'behandlingar',
  },
  {
    path: 'produkter',
    loadChildren: () =>
      import('@pages/products/products.module').then((m) => m.ProductsModule),
    data: {
      name: 'PRODUCTS.TITLE',
      title: 'PRODUCTS.TITLE',
      description: 'PRODUCTS.DESCRIPTION',
    },
  },
  {
    path: 'kurser',
    loadChildren: () =>
      import('@pages/courses/courses.module').then((m) => m.CoursesModule),
    data: {
      name: 'COURSES.TITLE',
      title: 'COURSES.TITLE',
      description: 'COURSES.DESCRIPTION',
    },
  },
  {
    path: 'vanliga-fragor',
    loadChildren: () =>
      import('@pages/faq/faq.module').then((m) => m.FaqModule),
    data: {
      name: 'FAQ.TITLE',
      title: 'FAQ.TITLE',
      description: 'FAQ.DESCRIPTION',
    },
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('@pages/contact/contact.module').then((m) => m.ContactModule),
    data: {
      name: 'CONTACT.TITLE',
      title: 'CONTACT.TITLE',
      description: 'CONTACT.DESCRIPTION',
    },
  },
  {
    path: 'om-fotverket',
    loadChildren: () =>
      import('@pages/about/about.module').then((m) => m.AboutModule),
    data: {
      name: 'ABOUT.TITLE',
      title: 'ABOUT.TITLE',
      description: 'ABOUT.DESCRIPTION',
    },
  },
  {
    path: 'kassa',
    loadChildren: () =>
      import('@pages/checkout/checkout.module').then((m) => m.CheckoutModule),
    data: {
      name: 'CHECKOUT.TITLE',
      title: 'CHECKOUT.TITLE',
      index: false,
      skip: true,
      description: 'CHECKOUT.DESCRIPTION',
    },
  },
  {
    path: 'villkor',
    loadChildren: () =>
      import('@pages/polices/polices.module').then((m) => m.PolicesModule),
    data: {
      name: 'POLICIES.TITLE',
      title: 'POLICIES.TITLE',
      description: 'POLICIES.DESCRIPTION',
      skip: true,
    },
  },
  {
    path: '404',
    loadChildren: () =>
      import('@pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
    data: {
      title: '404',
      description: '404 page not found',
      index: false,
      skip: true,
    } as IFotverketData,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
