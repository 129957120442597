import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ISelectable,
  AutocompleteComponent,
} from '@features/autocomplete/autocomplete.component';
import { DropdownModalComponent } from '@modal/components/dropdown-modal/dropdown-modal.component';
import { BaseProductComponent } from '@shared/components/base-product/base-product.component';
import { EventType } from '@core/services/logging.service';
import { filter, takeUntil } from 'rxjs';
import { IShopifyProduct } from 'server/interfaces/shopify-interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-product-autocomplete',
  template: `<div class="product-search-desktop">
      <autocomplete
        [items]="products"
        (itemSelected)="closeAndRoute($event)"
        [template]="product"
        [placeholder]="'PRODUCTS.SEARCH.PLACEHOLDER' | translate"
      >
      </autocomplete>
    </div>
    <div class="product-search-mobile">
      <icon-button
        [class]="'btn-search show flex center center-vertical'"
        (whenClick)="showModal()"
        title="{{ 'PRODUCTS.SEARCH.OPEN' | translate }}"
      >
        <i class="fa-solid fa-search fa-lg color-white"></i>
      </icon-button>
    </div>
    <dropdown-modal #dropdown
      ><autocomplete
        #autocomplete
        [items]="products"
        (itemSelected)="closeAndRoute($event)"
        [template]="product"
        [placeholder]="'PRODUCTS.SEARCH.PLACEHOLDER' | translate"
        style="padding-bottom: 16px;"
      >
      </autocomplete>
    </dropdown-modal>

    <ng-template #product let-item>
      <div class="flex autocomplete-product">
        <div
          class="flex center center-vertical autocomplete-product-image-container"
        >
          @if (item.imgSrc) {
          <img src="{{ item.imgSrc }}" alt="{{ item.imgAlt }}" />
          }
        </div>
        <div class="flex center-vertical vertical left">
          <span [innerHTML]="item.text"></span>
          <span
            [innerHTML]="
              item.object.variants[0].price
                | currency
                  : ''
                  : 'symbol'
                  : (item.object.variants[0].price % 1 == 0 ? '1.0-0' : '1.2-2')
            "
          ></span>
        </div>
      </div>
    </ng-template>`,
  styleUrls: ['./product-autocomplete.component.scss'],
})
export class ProductAutocompleteComponent
  extends BaseProductComponent
  implements OnInit
{
  public products: ISelectable<IShopifyProduct>[] = [];
  @Output() productSelected = new EventEmitter<IShopifyProduct>();

  @ViewChild('dropdown') private dropdown!: DropdownModalComponent;

  @ViewChild('autocomplete')
  private autocomplete!: AutocompleteComponent<IShopifyProduct>;
  constructor() {
    super();
  }
  ngOnInit(): void {
    this.productService.products$
      .pipe(
        filter((products) => products.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        next: (products) => {
          this.products = this.productsToSelectableList(products);
        },
      });
  }

  private productsToSelectableList(
    products: IShopifyProduct[]
  ): ISelectable<IShopifyProduct>[] {
    return products.map(
      (p) =>
        ({
          object: p,
          id: p.id.toString(),
          text: p.title,
          metaData: p.body_html + p.tags,
          imgSrc: p.image
            ? p.image.src
            : '../../../../assets/img/fotverket-logo.png',
          imgAlt: p.title,
        } as ISelectable<IShopifyProduct>)
    );
  }

  protected showModal(): void {
    this.dropdown.displayModal(true);
    this.autocomplete.focus();
    this.loggingService.logEvent('mobileSearch', EventType.click);
  }

  protected closeAndRoute(product: IShopifyProduct): void {
    if (this.dropdown.showing()) {
      this.dropdown.displayModal(false);
    }
    this.logRouteEvent(product);
    this.router.navigate([`${this.routeToDetails(product).replace('.', '')}`]);
  }
}
