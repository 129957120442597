import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading.component';
import { ModalModule } from '@modal/modal.module';

@NgModule({
  declarations: [LoadingComponent],
  exports: [LoadingComponent],
  imports: [ModalModule],
})
export class LoadingModule {}
