import { makeStateKey } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export abstract class TranslationLoader implements TranslateLoader {
  protected readonly key = makeStateKey<any>(`browser-language`);
  abstract getTranslation(lang: string): Observable<any>;
  public languageIsSupporterd(language: string): boolean {
    return SUPPORTED_LANGUAGES.includes(language as LanguageShortName);
  }
}
export type LanguageShortName = 'en' | 'sv';
export const SUPPORTED_LANGUAGES: LanguageShortName[] = ['en', 'sv'];
