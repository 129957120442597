import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';

import { debounceTime, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@core/services/platform.service';
import { DomService } from '@core/services/dom.service';
import { NavigationService } from '@core/services/navigation.service';
import { ToastService } from '../../../core/services/toast.service';
import { LoadingService } from './services/loading.service';
import { ModalComponent } from '@modal/components/modal/modal.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'loading',
  template: `<modal #loadingModal [isLocal]="isLocal">
    <div class="loading">
      <label class="loading-text">{{ message }}</label>
      <div class="spinner-border text-primary loading" role="status"></div>
    </div>
  </modal> `,
  styles: [
    `
      @import '/src/styles/variables';
      .loading-text {
        color: $secondary;
        position: relative;
        top: -4%;
        left: 17%;
        font-size: 22px;
      }

      .loading {
        width: 250px;
        height: 250px;
      }
    `,
  ],
})
export class LoadingComponent extends BaseComponent implements OnInit {
  @Input() public isLocal: boolean = false;
  @Input() public set loading(value: boolean) {
    value
      ? this.loadingModal.show(this.isLocal)
      : this.loadingModal.hide(!this.isLocal);
  }
  @ViewChild('loadingModal') private loadingModal: ModalComponent =
    new ModalComponent(
      this.toastService,
      this.navigationService,
      this.domService,
      this.platformService,
      this.cdr
    );
  public message?: string;
  constructor(
    protected loadingService: LoadingService,
    protected toastService: ToastService,
    protected navigationService: NavigationService,
    private domService: DomService,
    private platformService: PlatformService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadingService.loading$
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(200))
      .subscribe({
        next: (loading) => {
          if (loading && !this.isLocal) {
            this.message = loading.message;
            loading.loading
              ? this.loadingModal.show(false)
              : this.loadingModal.hide();
            this.cdr.detectChanges();
            return;
          }

          this.loadingModal.hide();
          this.cdr.detectChanges();
        },
      });
  }
}
