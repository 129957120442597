import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '@src/environments/environment';
@Directive({
  selector: '[datacy]',
  standalone: true,
})
export class DataCyDirective implements OnInit {
  @Input() public datacy?: string;
  constructor(
    private el: ElementRef<HTMLElement>,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    if (
      !this.datacy ||
      this.datacy === '' ||
      this.platformService.isServer ||
      !environment.isTest
    ) {
      return;
    }

    this.el.nativeElement.setAttribute('data-cy', this.datacy);
  }
}
