import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService, SeverityLevel } from './logging.service';
import { environment } from '@environment/environment';
import { ToastService, ToastType } from '@core/services/toast.service';
import { BaseComponent } from '@standalone/base-component.component';
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptorService
  extends BaseComponent
  implements HttpInterceptor
{
  constructor(
    private loggingService: LoggingService,
    private toastService: ToastService
  ) {
    super();
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loggingService.log(
          error.error ? error.error.message : error.message,
          SeverityLevel.warning
        );
        try {
          if (environment.isTest) {
            this.toastService.showToast(`${error.status}`, ToastType.danger);
          }
        } catch (error) {}
        return throwError(() => error);
      })
    );
  }
}
