import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  CookieConsentService,
  IFotverketCookie,
} from '@core/services/cookie-consent.service';
import { CookieService } from '@core/services/cookie.service';
import { DomService } from '@core/services/dom.service';
import { EventType, LoggingService } from '@core/services/logging.service';
import { environment } from '@environment/environment';
import { BaseComponent } from '@standalone/base-component.component';
import { take, takeUntil } from 'rxjs';
import {
  LanguageShortName,
  SUPPORTED_LANGUAGES,
} from 'shared/TranslationLoader';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-language',
  template: ` @for (language of availableLanguages; track language) { @defer (on
    immediate) {
    <button
      class="language-button"
      [class.active]="currentLanguage === language"
      (click)="changeLanguage(language)"
      title="{{ 'CHANGE_LANGUAGE' | translate : { language } }}"
    >
      <span [class]="getFlagClass(language)"></span>
    </button>
    } @placeholder {
    <skeleton [skeletonWidth]="33.33" [skeletonHeight]="32"></skeleton>
    } }`,
  styles: [
    `
      :host {
        float: right;
        display: flex;
      }
      @import '/src/styles/variables';
      .language-button {
        border: none;
        background: $secondary;
        height: 32px;
        &.active {
          background: #ededed;
        }
      }
    `,
  ],
})
export class LanguageComponent extends BaseComponent {
  private loadedLangs: LanguageShortName[] = [];
  protected currentLanguage: string;
  protected availableLanguages: LanguageShortName[] = SUPPORTED_LANGUAGES;
  constructor(
    private loggingService: LoggingService,
    private domService: DomService,
    private cookieConsentService: CookieConsentService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.currentLanguage = this.domService.document.documentElement.lang;
  }
  protected getFlagClass(language: LanguageShortName): string {
    let className = 'fi fi-';
    switch (language) {
      case 'en':
        className += 'gb';
        break;
      case 'sv':
        className += 'se';
        break;
      default:
        break;
    }
    return className;
  }

  protected async changeLanguage(language: LanguageShortName): Promise<void> {
    if (language === this.translate.currentLang) {
      return;
    }
    const cookie = this.cookieService.tryGet<IFotverketCookie>(
      environment.cookies.cookieName
    );
    if (cookie) {
      cookie.language = language;
      this.cookieConsentService.setCookie(cookie);
    }

    if (!this.loadedLangs.includes(language)) {
      this.translate
        .reloadLang(language)
        .pipe(take(1))
        .subscribe(() => {
          this.translate.use(language);
          this.loadedLangs.push(language);
          this.cdr.detectChanges();
        });
    } else {
      this.translate.use(language);
    }
    this.currentLanguage = language;
    const htmlLanguage = this.domService.document.documentElement.lang;
    if (htmlLanguage !== language) {
      this.domService.document.documentElement.lang = language;
    }
    this.loggingService.logEvent('changeLanguage', EventType.click, {
      language,
    });
    this.cdr.detectChanges();
  }
}
