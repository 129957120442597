<footer class="flex vertical right footer-background">
  <div class="foot">
    <div class="row text-center" style="max-width: 100%">
      <div class="col">
        <h2>{{ "CONTACT.TITLE" | translate }}</h2>
        <p>
          <i
            class="fa-solid fa-phone margin-right"
            title="{{
              'CONTACT.PHONENUMBER'
                | translate : { subject: contactInfo.companyName }
            }}"
          ></i>
          <a
            href="tel:{{ contactInfo.phone }}"
            target="_top"
            rel="noopener"
            title="{{
              'CONTACT.PHONENUMBER'
                | translate : { subject: contactInfo.companyName }
            }}"
            class="capitalize"
            >{{ contactInfo.phone }}</a
          >
        </p>
        <p>
          <i
            class="fa-solid fa-at margin-right"
            title="{{
              'CONTACT.EMAILADDRESS'
                | translate : { subject: contactInfo.companyName }
            }}"
          ></i>
          <a
            href="mailto:{{ contactInfo.email }}"
            target="_top"
            rel="noopener"
            title="{{
              'CONTACT.EMAILADDRESS'
                | translate : { subject: contactInfo.companyName }
            }}"
            >{{ contactInfo.email }}</a
          >
        </p>
        <p>{{ contactInfo.orgNr }}</p>
        <p>{{ contactInfo.streetAddress }}</p>
        <p>{{ contactInfo.postalCode }} {{ contactInfo.city }}</p>
      </div>
      <div class="col">
        <h2>{{ "POLICIES.TITLE" | translate }}</h2>
        <core-policies></core-policies>
      </div>
      <div class="col flex vertical center">
        <picture>
          <source
            srcset="../../assets/img/fotverket-logo.webp"
            type="image/webp"
          />
          <source
            srcset="../../assets/img/fotverket-logo.png"
            type="image/png"
          />
          <img
            src="../../assets/img/fotverket-logo.png"
            alt="Fotverket logotyp"
            loading="lazy"
          />
        </picture>
      </div>
    </div>
    <hr style="margin-top: 5px; margin-bottom: 10px" />
    <div class="flex center" style="min-height: 41px">
      @defer (on viewport; when widgetLoaded; ) {
      <!-- TrustBox widget - Review Collector -->
      <div
        #trustbox
        class="trustpilot-widget"
        data-locale="sv-SE"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="66601e3456066d0a9a30b5d1"
        data-style-height="41px"
        data-style-width="100%"
        style="margin: 0"
      >
        <a
          href="https://se.trustpilot.com/review/fotverket.se"
          target="_blank"
          rel="noopener"
          >Trustpilot</a
        >
      </div>
      <!-- End TrustBox widget -->
      } @placeholder {
      <skeleton [skeletonHeight]="41" [skeletonWidth]="331"></skeleton>
      }
    </div>
    <div class="copyright text-center">
      <p>Copyright Fotverket © {{ year }}</p>
    </div>
  </div>
</footer>
