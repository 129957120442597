@if (lineItem) {
<div class="flex vertical center-vertical lineItem-card">
  <div class="flex center-vertical space full-width">
    <label>{{ lineItem.title }}</label>
    <icon-button (whenClick)="deleteCartItem()" [class]="'btn-delete-product'">
      <i class="fa-regular fa-x fa-lg"></i>
    </icon-button>
  </div>
  <div class="flex center full-width left">
    @defer (on viewport) {
    <img
      [src]="lineItem.imageSrc"
      height="auto"
      width="auto"
      style="padding: 10px; max-width: 129px"
      (click)="routeToProductPage()"
      class="is-clickable"
    />
    } @placeholder {
    <skeleton [skeletonWidth]="129"></skeleton>
    }
    <div class="flex vertical right">
      @for (option of $any(lineItem.selectedOptions); track option.key) { @if
      (option.key.toLowerCase() !== 'title') {
      <label class="capitalize">{{ option.value }}</label>
      } }
      <label class="capitalize">{{
        lineItem.price
          | currency
            : ""
            : "symbol"
            : (lineItem.price % 1 == 0 ? "1.0-0" : "1.2-2")
      }}</label>
      <item-amount
        [id]="'cart-' + lineItem.variantId"
        [quantity]="lineItem.quantity"
        [small]="true"
        [allowZero]="true"
        (amountChanged)="updateLineItem($event)"
      ></item-amount>
    </div>
  </div>
</div>
}
