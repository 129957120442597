import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EventType, LoggingService } from '@core/services/logging.service';
import { BaseComponent } from '@standalone/base-component.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fot-button',
  template: `<button
    #button
    unFocus
    [datacy]="dataCy"
    [type]="type"
    [classList]="classes"
    [class.primary]="primary"
    [class.secondary]="secondary"
    [disabled]="disable"
    title="{{ title ? title : button.innerText }}"
    (click)="click($event)"
  >
    <ng-content></ng-content>
  </button>`,
  styles: [],
})
export class ButtonComponent extends BaseComponent {
  @ViewChild('button') private button!: ElementRef<HTMLButtonElement>;
  @Input() dataCy?: string;
  @Input() disable: boolean = false;
  @Output() clicked: EventEmitter<Event> = new EventEmitter();
  @Input() primary: boolean = false;
  @Input() secondary: boolean = false;
  @Input() classes: string = 'btn btn-fotverket';
  @Input() type: ButtonType = 'button';
  @Input() title: string | undefined;
  @Input() route?: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {
    super();
  }

  protected click(event: Event): void {
    this.button.nativeElement.blur();
    this.clicked.emit(event);
    this.loggingService.logEvent(
      this.title ? this.title : this.button.nativeElement.innerText,
      EventType.click
    );
    this.cdr.detectChanges();
  }
}

type ButtonType = 'button' | 'submit';
