import { Injectable } from '@angular/core';
import {
  AsyncValidatorFn,
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { BaseComponent } from '@standalone/base-component.component';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService extends BaseComponent {
  constructor(private fb: FormBuilder) {
    super();
  }

  public parseData<T>(
    data: T,
    validators?: any
  ):
    | UntypedFormGroup
    | UntypedFormArray
    | [any, ValidatorFn[], AsyncValidatorFn[]] {
    if (Array.isArray(data)) {
      return this.fb.array(
        data.map((item, index) =>
          this.parseData(item, (validators || {})[index])
        ),
        validators || {}
      );
    }
    if (typeof data === 'object' && data !== null) {
      let formGroupContent = {} as any;
      for (const [key, value] of Object.entries(data)) {
        formGroupContent[key] = this.parseData(value, (validators || {})[key]);
      }
      return this.fb.group(formGroupContent);
    }
    const [syncValidators = [], asyncValidators = []] = validators || [];
    return [data, syncValidators, asyncValidators];
  }

  public get noEmit(): any {
    return { emitEvent: false };
  }
}
