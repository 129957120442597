import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs';

import { PlatformService } from '@core/services/platform.service';
import { BaseComponent } from '@standalone/base-component.component';
import { DomService } from '@core/services/dom.service';
import { NavigationService } from '@core/services/navigation.service';
import { ToastService } from '@core/services/toast.service';
import * as uuid from 'uuid';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'modal',
  template: `@if (display) {
    <div class="modal-background flex center" [class.local]="isLocal">
      <div class="flex vertical center">
        <div class="flex even"><ng-content></ng-content></div>
      </div>
    </div>
    }`,
  styles: [],
})
export class ModalComponent extends BaseComponent implements OnInit {
  @Input() public isLocal = false;
  @Output() public onShow: EventEmitter<void> = new EventEmitter<void>();
  public readonly id: string = uuid.v4();
  protected display: boolean = false;
  constructor(
    protected toastService: ToastService,
    protected navigationService: NavigationService,
    private domService: DomService,
    protected platformService: PlatformService,
    protected cdr: ChangeDetectorRef
  ) {
    super();
  }
  ngOnInit(): void {
    this.navigationService.navigationStart$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (event) => {
          this.displayModal(false);
          this.cdr.detectChanges();
        },
      });
  }

  public show(blur: boolean = true): void {
    this.toastService.hideToast();
    this.domService.document.body.classList.add('disable-scrolling');
    if (blur) {
      this.blurModal(true);
    }
    this.display = true;
    this.onShow.emit();
    this.cdr.detectChanges();
  }

  public hide(blur: boolean = true): void {
    this.domService.document.body.classList.remove('disable-scrolling');
    if (blur) {
      this.blurModal(false);
    }
    this.display = false;
    this.cdr.detectChanges();
  }

  public showing(): boolean {
    return this.display;
  }

  public displayModal(value: boolean): void {
    value ? this.show() : this.hide();
  }

  private blurModal(value: boolean): void {
    var blurElements =
      this.domService.document.getElementsByClassName('canBlur');
    for (let index = 0; index < blurElements.length; index++) {
      const element = blurElements[index];
      if (value) {
        element.classList.add('blur');
      } else {
        element.classList.remove('blur');
      }
    }
    this.cdr.detectChanges();
  }
}
