import {
  Injectable,
  SecurityContext,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { IFotverketData } from '@src/environments/settings-interface';
import { BaseComponent } from '@standalone/base-component.component';
import {
  IShopifyPolicy,
  IShopifyProduct,
} from 'server/interfaces/shopify-interface';
import { environment } from '@src/environments/environment';
import { takeUntil } from 'rxjs';
import { PlatformService } from './platform.service';
import { DomService } from './dom.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService extends BaseComponent {
  public currentTitle: string = '';
  private currentTags: Map<MetaTagName, string> = new Map();
  constructor(
    private meta: Meta,
    private title: Title,
    private domService: DomService
  ) {
    super();
    this.addTags();
    this.translate.onLangChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() =>
        this.currentTags.forEach((v, k) => {
          if (k === MetaTagName.Title) {
            this.setTitle(v);
          } else {
            this.updateMetaTag(v, k);
          }
        })
      );
  }

  private addTags() {
    environment.metaTags.forEach((tag) => this.meta.addTag(tag));
  }

  public updateMetaData(data: IFotverketData): void {
    this.indexPage(data?.index);
    this.setTitle(data.title);
    this.updateMetaTag(data.description, MetaTagName.Description);
  }

  public policy(policy: IShopifyPolicy) {
    this.setTitle(policy.title);
    this.updateMetaTag(policy.title, MetaTagName.Description);
  }

  public product(product: IShopifyProduct) {
    this.setTitle(product.title);
    this.updateMetaTag(
      this.domService.sanitize.sanitize(
        SecurityContext.HTML,
        product.body_html
      ) ?? '',
      MetaTagName.Description
    );
  }

  private setTitle(title: string): void {
    if (!title) {
      return;
    }
    this.currentTags.set(MetaTagName.Title, title);
    this.currentTitle = `${this.translate.instant(title)} | ${
      environment.contactInfo.companyName
    }`;
    this.title.setTitle(this.currentTitle);
  }

  private updateMetaTag(content: string, name: MetaTagName): void {
    if (!content) {
      return;
    }
    this.currentTags.set(name, content);
    this.meta.updateTag(
      { content: this.translate.instant(content) },
      `name=${name}`
    );
  }

  private indexPage(shouldIndex?: boolean): void {
    shouldIndex = !!shouldIndex ? shouldIndex : !environment.isTest;
    this.updateMetaTag(shouldIndex ? 'index' : 'noindex', MetaTagName.Robots);
  }
}

export enum MetaTagName {
  Description = 'description',
  Robots = 'robots',
  Title = 'title',
}
