import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom, take } from 'rxjs';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '@environment/environment';
import { inject } from '@angular/core';
import { API_URL_V1 } from 'server/constants';
import { BaseComponent } from '@standalone/base-component.component';

export class BaseApiClientService extends BaseComponent {
  private _baseUrl = inject(API_URL_V1);
  private httpClient: HttpClient = inject(HttpClient);
  protected platformService: PlatformService = inject(PlatformService);
  protected readySubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private requestHeaders: HttpHeaders;
  constructor(url: string, baseUrl?: string) {
    super();
    if (baseUrl) {
      this._baseUrl = baseUrl;
    }
    this._baseUrl = !url.endsWith('/')
      ? `${this._baseUrl}/${url}`
      : `${this._baseUrl}/${url.substring(1, url.length - 1)}`;

    this.requestHeaders = new HttpHeaders({
      'X-Fotverket-Platform': this.platformService.platform,
      'X-Fotverket-Api-Key': environment.apiKey,
    });
  }

  protected get<T>(
    path: string = '',
    params?: HttpParams | { [params: string]: string | number | boolean }
  ): Observable<T> {
    const url = this.getUrl(path);
    return this.httpClient
      .get<T>(url, {
        params: params,
        withCredentials: true,
        headers: this.requestHeaders,
      })
      .pipe(take(1));
  }

  protected post<TReturn, TInput extends Object | undefined>(
    path: string = '',
    body?: TInput
  ): Observable<TReturn> {
    const url = this.getUrl(path);
    return this.httpClient
      .post<TReturn>(url, body, {
        withCredentials: true,
        headers: this.requestHeaders,
      })
      .pipe(take(1));
  }

  protected async patch<T>(path: string = '', body?: Object): Promise<T> {
    const url = this.getUrl(path);
    return await firstValueFrom(
      this.httpClient.patch<T>(url, body, {
        withCredentials: true,
        headers: this.requestHeaders,
      })
    );
  }

  protected postForm<T>(path: string, body: Object): Observable<T> {
    const url = this.getUrl(path);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient
      .post<T>(url, body, { headers: headers })
      .pipe(take(1));
  }

  protected delete<T>(path: string): Observable<T> {
    const url = this.getUrl(path);
    return this.httpClient
      .delete<T>(url, {
        withCredentials: true,
        headers: this.requestHeaders,
      })
      .pipe(take(1));
  }

  private getUrl(path: string): string {
    if (path === '') {
      return this._baseUrl;
    }
    const url = path.startsWith('/')
      ? path.substring(1, path.length - 1)
      : path;
    return `${this._baseUrl}/${url}`;
  }

  public get ready$(): Observable<boolean> {
    return this.readySubject.asObservable();
  }
}
