import { Injectable } from '@angular/core';
import { Environment } from '@environment/settings-interface';
import { environment } from '@src/environments/environment';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
type SameSite = 'Lax' | 'Strict' | 'None';
@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private cookieService: SsrCookieService) {}

  public getAsString(cookieName: string): string {
    const s = this.cookieService.get(cookieName);
    // stupid express cookieParser
    return s.startsWith('j:') ? s.slice(2) : s;
  }

  public get<T>(cookieName: string): T {
    return JSON.parse(this.getAsString(cookieName)) as T;
  }

  public tryGet<T>(cookieName: string): T | undefined {
    if (!this.check(cookieName)) {
      return undefined;
    }
    return this.get<T>(cookieName);
  }

  public check(cookieName: string): boolean {
    return this.cookieService.check(cookieName);
  }

  public delete(cookieName: string): void {
    this.cookieService.delete(cookieName);
  }

  public set(
    name: string,
    value: string,
    expires?: Date | undefined,
    path?: string | undefined
  ): void {
    this.cookieService.set(
      name,
      value,
      expires,
      path,
      environment.hostName,
      true,
      'Strict'
    );
  }

  public setAs<T>(
    name: string,
    value: T,
    expires?: Date | undefined,
    path?: string | undefined
  ): void {
    this.cookieService.set(
      name,
      JSON.stringify(value),
      expires,
      path,
      environment.hostName,
      true,
      'Strict'
    );
  }

  public deleteAll(): void {
    this.cookieService.deleteAll(
      '/',
      environment.environment === Environment.Dev
        ? environment.hostName
        : `.${environment.hostName}`
    );
  }
}
