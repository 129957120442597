import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    protected sanitizer: DomSanitizer
  ) {}
  public get window(): (Window & typeof globalThis) | null {
    return this.doc.defaultView;
  }

  public get document(): Document {
    return this.doc;
  }

  public get location(): Location {
    return this.doc.location;
  }

  public get navigator(): Navigator | undefined | null {
    return this.window?.navigator;
  }

  public get sanitize(): DomSanitizer {
    return this.sanitizer;
  }
}
