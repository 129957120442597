import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@standalone/base-component.component';
import { EventType, LoggingService } from '@core/services/logging.service';
import { IShopifyProduct } from 'server/interfaces/shopify-interface';
import { ProductService } from '@core/services/product.service';

@Component({
  selector: 'str-base-product',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseProductComponent extends BaseComponent {
  protected fotverketProduct: FotverketProduct = {} as FotverketProduct;
  @Input() public set product(value: IShopifyProduct) {
    this.fotverketProduct = new FotverketProduct(value);
  }
  @Input() public index: number = 0;
  protected cancelNavigation: boolean = false;
  protected router: Router = inject(Router);
  protected productService: ProductService = inject(ProductService);
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected loggingService: LoggingService = inject(LoggingService);
  constructor() {
    super();
  }

  protected logRouteEvent(product: IShopifyProduct): void {
    if (!this.cancelNavigation) {
      const handle = product ? product.handle : this.fotverketProduct.handle;
      const title = product
        ? product.title
        : this.fotverketProduct.product.title;
      this.loggingService.logEvent(
        `productDetails-${handle}`,
        EventType.click,
        { productTitle: title }
      );
    }
  }

  protected routeToDetails(product?: IShopifyProduct): string {
    const handle = product ? product.handle : this.fotverketProduct.handle;
    return `/produkter/${handle}`;
  }
}

export class FotverketProduct {
  public readonly hasMultiplePricePoints: boolean;
  public readonly displayPrice: string;
  public readonly available: boolean;
  public readonly new: boolean;
  public readonly originalPrice: string | undefined;
  constructor(public product: IShopifyProduct) {
    this.hasMultiplePricePoints = this.calculatePricePoints();
    this.displayPrice = this.calculateDisplayPrice();
    this.available = this.product.variants.some(
      (variant) => variant.inventory_quantity > 0
    );
    this.new = this.isNew();
    this.originalPrice = this.getOriginalPrice();
  }
  private calculateDisplayPrice() {
    return this.hasMultiplePricePoints
      ? [...this.product.variants].sort((a, b) =>
          a.price < b.price ? 1 : -1
        )[0].price
      : this.product.variants[0].price;
  }

  private calculatePricePoints() {
    const basePrice = +this.product.variants[0].price;
    let sumVariantPrices = 0;
    this.product.variants.forEach((v) => (sumVariantPrices += +v.price));

    return basePrice * this.product.variants.length !== sumVariantPrices;
  }

  public get displayImage(): string {
    return this.product.image?.src;
  }

  public get handle(): string {
    return this.product.handle;
  }

  private getOriginalPrice(): string | undefined {
    const compareAtprice = this.product.variants.find(
      (p) => p.compare_at_price
    )?.compare_at_price;
    return compareAtprice && +compareAtprice > +this.displayPrice
      ? compareAtprice
      : undefined;
  }

  private isNew(): boolean {
    const now = new Date();
    const breakpoint = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 14
    );
    const createdDate = new Date(this.product.created_at);
    return createdDate > breakpoint;
  }
}
