import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PolicyService } from '@core/services/policy.service';
import { BaseComponent } from '@standalone/base-component.component';

import { takeUntil } from 'rxjs';
import { IShopifyPolicy } from 'server/interfaces/shopify-interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-policies',

  template: `
    @defer(on viewport) {
    <div class="flex vertical center-vertical">
      @for (policy of (policyService.policies$ | async); track policy.title) {
      <a
        #policyLink
        [routerLink]="['villkor', policy.handle]"
        title="{{ policyLink.innerHTML }}"
        >{{ policy.title.replaceAll(' ', '_').toUpperCase() | translate }}</a
      >
      }
    </div>
    } @placeholder {
    <div class="flex vertical center-vertical">
      @for (placeholder of '123456'; track $index) {
      <skeleton [skeletonHeight]="30" [skeletonWidth]="100"></skeleton>
      }
    </div>
    }
  `,
  styles: [],
})
export class PoliciesComponent extends BaseComponent {
  constructor(protected policyService: PolicyService) {
    super();
  }
}
