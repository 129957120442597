<icon-button
  [datacy]="'cart-open'"
  [class]="'btn-shopping-cart show'"
  (whenClick)="dropdown.displayModal(true)"
  [title]="'CART.TITLE' | translate"
>
  <div
    #totalItemCountContainer
    class="flex center center-vertical cart-button-quantity"
  >
    <span [datacy]="'cart-total-quantity'">{{ totalItemcount }}</span>
  </div>
  <i class="fa-solid fa-cart-shopping fa-lg cart-icon"></i>
</icon-button>

<dropdown-modal #dropdown>
  <div
    #wrapper
    class="flex vertical space no-wrap left shopping-cart-wrapper"
    (scroll)="onScroll()"
  >
    <div class="cart-banner">
      <h2>{{ "CART.TITLE" | translate }}</h2>
      <hr />
    </div>
    <ng-template
      [ngTemplateOutlet]="lineItems.length > 0 ? items : cartEmpty"
    ></ng-template>
  </div>
</dropdown-modal>

<ng-template #items>
  <div
    class="flex vertical center-vertical space"
    style="width: 100%; margin-top: 0.5rem"
  >
    <div class="flex vertical center-vertical-mobile cart-items-container">
      @for (lineItem of lineItems; track lineItem.variantId) { @defer (on
      viewport) {
      <cart-item [lineItem]="lineItem"></cart-item>
      }@placeholder {
      <skeleton></skeleton>
      } }
    </div>
    <div
      #buttons
      class="flex vertical full-width sticky bottom empty-cart-wrapper"
    >
      <div class="flex even">
        <label>{{ "CHECKOUT.SUM_QUANTITY" | translate : { sum: "" } }}</label>
        <label>{{ totalItemcount }}</label>
      </div>
      <div class="flex even">
        <label>{{ "CHECKOUT.SUM_PRICE" | translate : { sum: "" } }}</label>
        <label>{{ totalPrice | currency : "" : "symbol" : "0.0-0" }}</label>
      </div>
      <div class="flex even">
        <fot-button
          [disable]="lineItems.length < 1 || updatingCart"
          [dataCy]="'cart-empty-cart'"
          (clicked)="clearCart()"
        >
          {{ "CART.EMPTY" | translate }}
        </fot-button>
        <fot-button
          [disable]="lineItems.length < 1 || updatingCart"
          [routerLink]="['kassa']"
          [datacy]="'cart-go-to-checkout'"
        >
          {{ "CART.TO_CHECKOUT" | translate }}
        </fot-button>
      </div>
      <div class="flex vertical text-center" style="padding-top: 20px">
        @if (lineItems.length > 0) {
        <ng-container [ngTemplateOutlet]="continueShopping"></ng-container>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cartEmpty>
  <div class="flex vertical center-vertical" style="padding-bottom: 16px">
    <p>{{ "CART.IS_EMPTY" | translate }}</p>
    <ng-container [ngTemplateOutlet]="continueShopping"></ng-container>
  </div>
</ng-template>

<ng-template #continueShopping>
  <a
    class="primary underline"
    [routerLink]="['produkter']"
    (click)="dropdown.displayModal(false)"
    >{{ "CART.BACK_TO_SHOPPING" | translate }}</a
  >
</ng-template>
