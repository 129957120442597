import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@features/button/button.module';
import { DropdownModalComponent } from './components/dropdown-modal/dropdown-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalComponent, PopupModalComponent, DropdownModalComponent],
  imports: [CommonModule, ButtonModule, TranslateModule],
  exports: [ModalComponent, PopupModalComponent, DropdownModalComponent],
})
export class ModalModule {}
