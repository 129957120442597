import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateService extends TranslateService {
  override instant(
    key: string | string[],
    interpolateParams?: Object | undefined
  ) {
    if (!key?.length) {
      return key;
    }

    return super.instant(key, interpolateParams);
  }
}
