import { Component, OnDestroy, inject } from '@angular/core';
import { environment } from '@environment/environment';
import { Environment } from '@environment/settings-interface';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  template: '',
  standalone: true,
  imports: [TranslateModule],
})
export class BaseComponent implements OnDestroy {
  protected ngUnsubscribe = new Subject<void>();
  protected translate = inject(TranslateService);

  constructor() {
    this.logComponent('Creating', ConsoleColor.green);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.logComponent('Destroying', ConsoleColor.red);
  }

  private logComponent(message: string, color: ConsoleColor): void {
    return;
    if (environment.environment !== Environment.Dev) {
      return;
    }
    console.log(
      '%s %c %s',
      `${this.constructor.name}:`,
      `color: ${color}`,
      message
    );
  }
}

enum ConsoleColor {
  green = 'green',
  red = 'red',
}
