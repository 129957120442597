import {
  Injectable,
  StateKey,
  makeStateKey,
  TransferState,
  inject,
} from '@angular/core';
import { environment } from '@environment/environment';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Observable, Subject, retry, takeUntil } from 'rxjs';
import {
  IWebsocketMessage,
  WebsocketMessageType,
} from 'shared/websocket-messages';
import { PlatformService } from './platform.service';
import { LoggingService, SeverityLevel } from '@core/services/logging.service';
import { APP_BASE_HREF } from '@angular/common';
import { BaseComponent } from '@standalone/base-component.component';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService extends BaseComponent {
  private webSocketSubject!: WebSocketSubject<IWebsocketMessage>;
  private url!: string;
  private messageSubject: Subject<IWebsocketMessage>;
  private stateKey: StateKey<string> = makeStateKey<string>(
    this.constructor.name
  );
  private readonly baseUrl = inject(APP_BASE_HREF, { optional: true });

  constructor(
    platformService: PlatformService,
    loggingService: LoggingService,
    state: TransferState
  ) {
    super();
    this.messageSubject = new Subject();
    if (!state.hasKey(this.stateKey) && platformService.isServer) {
      state.set(this.stateKey, this.baseUrl?.replace('http', 'ws'));
      return;
    }
    const url = state.get(
      this.stateKey,
      environment.apiTarget.replace('http', 'ws')
    );
    this.url = `${url}/websocket`;
    this.webSocketSubject = webSocket<IWebsocketMessage>(this.url);
    this.webSocketSubject
      .pipe(retry({ count: 10, delay: 5000 }), takeUntil(this.ngUnsubscribe))
      .subscribe((message) => {
        if (Object.values(WebsocketMessageType).includes(message.type)) {
          this.messageSubject.next(message);
          return;
        }
        loggingService.log(
          'unexpected socket value',
          SeverityLevel.warning,
          message
        );
      });
  }

  public get message$(): Observable<IWebsocketMessage> {
    return this.messageSubject.asObservable();
  }

  override ngOnDestroy(): void {
    if (this.webSocketSubject) {
      this.webSocketSubject.complete();
    }
    super.ngOnDestroy();
  }
}
