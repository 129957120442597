import { Injectable } from '@angular/core';
import { BaseApiClientService } from '@shared/services/base-api-client.service';
import { BehaviorSubject, filter, Observable, takeUntil } from 'rxjs';
import {
  IShopifyProduct,
  IShopifyProductVariant,
} from 'server/interfaces/shopify-interface';
import { WebsocketService } from './websocket.service';
import { WebsocketMessageType } from 'shared/websocket-messages';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseApiClientService {
  private productsSubject: BehaviorSubject<IShopifyProduct[]> =
    new BehaviorSubject<IShopifyProduct[]>([]);
  private products: IShopifyProduct[] = [];
  constructor(private websocketService: WebsocketService) {
    super('products');
    this.loadProducts();

    this.websocketService.message$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((m) => m.type === WebsocketMessageType.ProductsUpdated)
      )
      .subscribe(() => {
        this.loadProducts();
      });
  }

  private loadProducts() {
    this.get<IShopifyProduct[]>().subscribe({
      next: (products: IShopifyProduct[]) => {
        this.products = products;
        this.readySubject.next(true);
        this.productsSubject.next(this.products);
      },
    });
  }

  public getBySKu(sku: string): IShopifyProductVariant | undefined {
    const filter = (v: IShopifyProductVariant) =>
      v.sku.toLowerCase() === sku.toLowerCase() && v.inventory_quantity > 0;
    const product = this.products.find((p) => p.variants.some(filter));
    if (!product) {
      return;
    }

    return product.variants.find(filter);
  }

  public get products$(): Observable<IShopifyProduct[]> {
    return this.productsSubject.asObservable();
  }

  public get productTypes(): string[] {
    return this.products
      .map((p) => p.product_type)
      .filter(
        (value, index, array) => array.indexOf(value) === index && value != ''
      )
      .sort((a, b) => (a > b ? 1 : -1));
  }

  public get vendors(): string[] {
    return this.products
      .map((p) => p.vendor)
      .filter(
        (value, index, array) => array.indexOf(value) === index && value != ''
      )
      .sort((a, b) => (a > b ? 1 : -1));
  }

  public getByTags(tags: string): IShopifyProduct[] {
    if (!tags || !tags.length) {
      return [];
    }
    const products: IShopifyProduct[] = [];
    tags
      .split(',')
      .forEach((tag) =>
        this.products
          .filter(
            (p) =>
              !products.find((p2) => p2.id === p.id) &&
              p.tags.split(',').find((st) => st.trim() === tag.trim())
          )
          .forEach((p3) => products.push(p3))
      );

    return products;
  }

  public getVariantById(variantId: number): IShopifyProductVariant | undefined {
    let variantResult: IShopifyProductVariant | undefined;
    for (let product of this.products) {
      const variant = product.variants.find(
        (variant) => variant.id === variantId
      );
      if (variant) {
        variantResult = variant;
        break;
      }
    }
    return variantResult;
  }

  public getProductById(productId: number): IShopifyProduct | undefined {
    return this.products.find((product) => product.id === productId);
  }

  public getProductByHandle(handle: string): IShopifyProduct | undefined {
    return this.products.find((product) => product.handle === handle);
  }

  private getAverageVariantPrice(p: IShopifyProduct): number {
    return p.variants.reduce((n, v) => n + +v.price, 0) / p.variants.length;
  }

  public comparePrice(p1: IShopifyProduct, p2: IShopifyProduct): boolean {
    return this.getAverageVariantPrice(p1) > this.getAverageVariantPrice(p2);
  }

  public defaultSort(p1: IShopifyProduct, p2: IShopifyProduct): boolean {
    return p1.title > p2.title;
  }
}
