export enum WebsocketMessageType {
  ProductsUpdated = 'products updated',
  General = 'general',
}

export interface IWebsocketMessage {
  type: WebsocketMessageType;
  message?: string;
  [key: string]: any;
}
