import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';

@Component({
  standalone: true,
  selector: 'skeleton',
  template: ` <div #skeleton class="pulse"></div>`,
  styles: [
    `
      :host {
        display: block;
        background: rgb(239, 241, 246) no-repeat;
        padding: 4px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class SkeletonComponent extends BaseComponent implements AfterViewInit {
  private parentpadding = 8;
  @ViewChild('skeleton') skeleton!: ElementRef<HTMLDivElement>;
  @Input() skeletonHeight: number = 0;
  @Input() skeletonWidth: number = 0;
  @Input() fullWidth: string = `100vw - ${this.parentpadding}px`;
  @Input() fullHeight: string = `100%`;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }
  ngAfterViewInit(): void {
    this.skeletonHeight = this.skeletonHeight - this.parentpadding;
    this.skeletonWidth = this.skeletonWidth - this.parentpadding;
    this.skeleton.nativeElement.style.height =
      this.skeletonHeight <= 0
        ? `calc(${this.fullHeight} - ${this.parentpadding}px)`
        : `${this.skeletonHeight}px`;
    this.skeleton.nativeElement.style.width =
      this.skeletonWidth <= 0
        ? `calc(${this.fullWidth} - ${this.parentpadding}px)`
        : `${this.skeletonWidth}px`;
    this.cdr.detectChanges();
  }
}
