import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-page-content',
  template: `<section
    class="container-fluid flex vertical flex-grow page-content"
  >
    <ng-content></ng-content>
  </section>`,
  styles: [
    `
      @import '/src/styles/variables';
      .flex-grow {
        flex: 1 0 auto;
        padding: 0;
      }
      .page-content {
        max-width: $maxWidth;
        min-height: $fitScreenDesktop;
        @media (max-width: $mobileBreakpoint) {
          min-height: $fitScreenMobile;
        }
      }
    `,
  ],
})
export class PageContentComponent extends BaseComponent {
  constructor() {
    super();
  }
}
