import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data } from '@angular/router';
import { combineLatestWith, filter, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@standalone/base-component.component';
import { NavigationService } from '@core/services/navigation.service';
import { PolicyService } from '@core/services/policy.service';
import { ProductService } from '@core/services/product.service';
import { StructuredDataFactoryService } from '@core/services/structured-data-factory.service';
import { LoggingService } from '@core/services/logging.service';
import { SeoService } from '@core/services/seo.service';
import { IFotverketData } from '@environment/settings-interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-breadcrumbs',
  template: `
    <nav aria-label="breadcrumb" class="breadcrumb-container">
      @if (breadcrumbs.length > 1) { @defer (on viewport) {
      <ol class="breadcrumb">
        @for (breadcrumb of breadcrumbs; track breadcrumb.label; let i = $index)
        {
        <li class="breadcrumb-item">
          @if (i !== breadcrumbs.length - 1) {
          <a
            class="breadcrumb-link"
            [routerLink]="[breadcrumb.url]"
            title="{{ breadcrumb.label | translate }}"
          >
            <span>{{ breadcrumb.label | translate }}</span>
          </a>
          } @else {
          <span>{{ breadcrumb.label | translate }}</span>
          }
        </li>
        }
      </ol>
      } @placeholder {
      <skeleton
        style="width: calc(100% - 67px)"
        [skeletonHeight]="33"
        fullWidth="100%"
      ></skeleton>
      } }
    </nav>
  `,
  styles: [
    `
      @import '/src/styles/variables';
      @import '/src/styles/styling';
      :host {
        display: block;
        width: calc(100% - 67px);
      }
      .breadcrumb-container {
        height: 33px;
      }
      ol.breadcrumb {
        @extend .ellipsis;
        list-style: none; /* Removes the bullet points from the list items */
        padding: 0;
        padding-left: $doublePadding;
        margin: 0;
        background: $secondary;
        height: 28px;
        display: block;
      }

      a.breadcrumb-link {
        color: #666;
        min-width: 18px;
        > mat-icon {
          vertical-align: bottom;
        }
      }

      .breadcrumb-item {
        display: inline-block; /* Display the list items horizontally */
        padding-left: 0;
        white-space: nowrap;
        display: inline;
      }

      .breadcrumb-item:not(:last-child)::after {
        content: '›'; /* Adds a separator character after each list item, except the last one */
        margin: 0 0.5rem; /* Adds some spacing between the separator character and the next list item */
      }

      .breadcrumb-item::before {
        content: ''; /* Adds a separator character after each list item, except the last one */
      }
    `,
  ],
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {
  private _breadcrumbs: IBreadcrumb[] = [];
  protected get breadcrumbs(): IBreadcrumb[] {
    return this._breadcrumbs;
  }
  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private policyService: PolicyService,
    protected structuredDataFactoryService: StructuredDataFactoryService,
    private loggingService: LoggingService,
    private seoService: SeoService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.cdr.detectChanges());
    this.navigationService.navigationEnd$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(
          this.productService.ready$.pipe(
            takeUntil(this.ngUnsubscribe),
            filter((r) => r)
          )
        ),
        map(() => this.generateBreadcrumbs())
      )
      .subscribe((breadcrumbs) => {
        this._breadcrumbs = breadcrumbs;
        this.cdr.detectChanges();
      });
  }

  private generateBreadcrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [
      {
        label: 'HOME.NAME',
        url: '/',
        icon: 'home',
      },
    ];

    let route: ActivatedRouteSnapshot | null = null;
    let next: ActivatedRouteSnapshot | null = this.activatedRoute.snapshot;
    while (next) {
      route = next;
      next = next.firstChild;
      const data: Data | undefined = route.data;
      const url = route.routeConfig?.path;
      let label: string = '';
      const handle = route.url[0]?.path;
      const hasHandle = url?.includes(':');
      let product;
      if (hasHandle && handle && !handle.includes('kassa')) {
        product = this.productService.getProductByHandle(handle);
        const productTitle = product?.title;
        const policyTitle = this.policyService.getPolicyByHandle(handle)?.title;
        label = productTitle
          ? productTitle
          : policyTitle
          ? policyTitle
          : handle;
      } else {
        label = data['name'];
      }

      if (!label) {
        continue;
      }

      const previousUrl = breadcrumbs[breadcrumbs.length - 1]?.url;
      const breadcrumb = {
        label: label,
        url: `${breadcrumbs.length === 1 ? '' : previousUrl}/${
          hasHandle ? handle : url
        }`,
      };
      const isProductDetails = data['name'] === 'ProductDetails';
      if (isProductDetails) {
        data['title'] = breadcrumbs[breadcrumbs.length - 1].label;
      }
      if (product) {
        data['description'] = product.body_html;
      }
      if (data) {
        this.seoService.updateMetaData(data as IFotverketData);
      }

      if (
        isProductDetails ||
        !label ||
        breadcrumbs.map((b) => b.label).includes(breadcrumb.label) ||
        breadcrumbs.map((b) => b.url).includes(breadcrumb.url) ||
        !next
      ) {
        continue;
      }

      breadcrumbs.push(breadcrumb);
    }
    this.structuredDataFactoryService.generateForBreadcrumbs(
      'breadcrumbs',
      breadcrumbs.map((b) => {
        const crumb = { ...b };
        crumb.label = this.translate.instant(crumb.label);
        return crumb;
      })
    );
    return breadcrumbs;
  }
}

export interface IBreadcrumb {
  label: string;
  url: string;
  icon?: string;
}
