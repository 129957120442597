import { Injectable } from '@angular/core';
import { EventType, LoggingService } from '@core/services/logging.service';
import { environment } from '@src/environments/environment';

import { BehaviorSubject, Subject, takeUntil, Observable } from 'rxjs';
import {
  IShopifyProductVariant,
  IShopifyProduct,
} from 'server/interfaces/shopify-interface';
import { ProductService } from './product.service';
import { BaseComponent } from '@standalone/base-component.component';
import { PlatformService } from './platform.service';
import { CookieService } from '@core/services/cookie.service';
import { LoadingService } from '@features/loading/services/loading.service';
import { IFotverketCart } from 'server/interfaces/fotverket-interface';
import { ICartVariant } from '@features/shopping-cart/shopping-cart.component';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService extends LoadingService {
  private cartCreatedSubject: BehaviorSubject<IFotverketCart>;
  private cartDeletedSubject: Subject<void> = new Subject<void>();
  private cartOpenSubject: Subject<boolean> = new Subject<boolean>();
  private cartCookieName: string = environment.cookies.cartCookieName;
  private cart: FotverketCart;

  constructor(
    private cookieService: CookieService,
    private loggingService: LoggingService,
    private productService: ProductService,
    platformService: PlatformService
  ) {
    super();
    this.cart = new FotverketCart(
      this.productService,
      this.tryGetCartCookie(),
      platformService
    );

    this.cartCreatedSubject = new BehaviorSubject<IFotverketCart>(this.cart);
    this.cartUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.addUpdateCookie();
    });
  }

  public cartValue(): number {
    return this.cartCreatedSubject.value.totalPrice;
  }

  public async delete(): Promise<void> {
    this.cookieService.delete(this.cartCookieName);
    this.cartDeletedSubject.next();
    this.cart.clear();
  }

  private tryGetCartCookie(): ICartCookie {
    const cookie = this.cookieService.tryGet<ICartCookie>(this.cartCookieName);
    if (cookie) {
      return cookie;
    } else {
      return this.addUpdateCookie();
    }
  }

  public get cartCreated$(): Observable<IFotverketCart> {
    return this.cartCreatedSubject.asObservable();
  }

  public get cartDeleted$(): Observable<void> {
    return this.cartDeletedSubject.asObservable();
  }

  public get cartUpdated$(): Observable<ICartUpdated> {
    return this.cart.updated$;
  }

  public updateLineItems(lineItem: ICartProductVariant): void {
    this.increaseLoading();
    this.cart?.update(lineItem);
    this.decreaseLoading();
  }

  public addToCart(lineItem: ICartProductVariant): void {
    this.increaseLoading();
    this.loggingService.logEvent(`addToCart`, EventType.addToCart, lineItem);
    this.cart.add(lineItem);
    this.decreaseLoading();
  }

  public clearCart(): void {
    this.cart?.clear();
  }

  public removeLineItem(variantIds: number[]): void {
    this.cart?.remove(variantIds);
  }

  private addUpdateCookie(): ICartCookie {
    const aMonthFromNow = new Date();
    aMonthFromNow.setMonth(aMonthFromNow.getMonth() + 1);
    const value = this.cart
      ? this.cart.generateCookie()
      : ({ cart: [] } as ICartCookie);
    this.cookieService.setAs(this.cartCookieName, value, aMonthFromNow, '/');

    return value;
  }

  public cartOpen(open: boolean): void {
    this.cartOpenSubject.next(open);
  }

  public get open$(): Observable<boolean> {
    return this.cartOpenSubject.asObservable();
  }

  convertToCartVariant(lineItems: IFotverketProductVariant[]): ICartVariant[] {
    const cartItems: ICartVariant[] = [];
    lineItems.forEach((li) => {
      const product = this.productService.getProductById(li.productId);
      if (product) {
        const cartVariant = {
          productId: li.productId,
          variantId: li.variant.id,
          title: li.title,
          quantity: li.quantity,
          selectedOptions: product.options.map((option) => ({
            key: option.name,
            value: li.variant[`option${option.position}`],
          })),
          imageSrc: li.imageSrc,
          handle: li.handle,
          price: +li.variant.price,
        } as ICartVariant;
        cartItems.push(cartVariant);
      }
    });

    return cartItems;
  }
}

export interface ICartCookie {
  cart: ICartProductVariant[];
}

export interface ICartProductVariant {
  productId: number;
  variantId: number;
  quantity: number;
}

export interface IFotverketProductVariant {
  variant: IShopifyProductVariant;
  quantity: number;
  handle: string;
  title: string;
  productId: number;
  imageSrc: string;
}

export class FotverketProductVariant implements IFotverketProductVariant {
  constructor(
    variant: IShopifyProductVariant,
    quantity: number,
    product: IShopifyProduct
  ) {
    this.variant = variant;
    this.quantity = quantity;
    this.title = product?.title ?? '';
    this.handle = product?.handle ?? '';
    this.productId = product?.id ?? 0;
    this.imageSrc =
      product.images.find((image) => image.id === variant.image_id)?.src ??
      product.image.src;
  }
  variant: IShopifyProductVariant;
  quantity: number;
  handle: string;
  title: string;
  productId: number;
  imageSrc: string;
}

export interface ICartUpdated {
  type: CartUpdated;
  cart: IFotverketCart;
  message?: string;
}

export enum CartUpdated {
  ItemAdded = 'itemAdded',
  Created = 'created',
  ItemRemoved = 'itemRemoved',
  Empty = 'empty',
  QuantityChanged = 'quantityChanged',
}

export class FotverketCart extends BaseComponent implements IFotverketCart {
  private updatedSubject: BehaviorSubject<ICartUpdated>;
  items: IFotverketProductVariant[];
  totalQuantity: number;
  totalPrice: number;
  private updateTimeout: NodeJS.Timeout | undefined;
  constructor(
    private productService: ProductService,
    cookie: ICartCookie,
    private platformService: PlatformService
  ) {
    super();
    this.totalQuantity = 0;
    this.totalPrice = 0;
    this.items = [];
    this.updatedSubject = new BehaviorSubject<ICartUpdated>({
      cart: this,
      type: CartUpdated.Created,
    });

    this.productService.ready$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((ready) => {
        if (ready) {
          this.addItems(cookie);
        }
      });
  }

  private addItems(cookie: ICartCookie) {
    cookie.cart.forEach((cartItem) => {
      const product = this.productService.getProductById(cartItem.productId);
      const variant = product?.variants.find(
        (variant) => variant.id === cartItem.variantId
      );
      if (product && variant) {
        const quantity =
          cookie.cart.find((item) => item.variantId === variant.id)?.quantity ??
          0;
        this.totalQuantity += quantity;
        this.totalPrice += +variant.price * quantity;
        this.items.push(
          new FotverketProductVariant(variant, quantity, product)
        );
      }
    });
  }

  public get updated$(): Observable<ICartUpdated> {
    return this.updatedSubject.asObservable();
  }

  private updated(type: CartUpdated, message?: string): void {
    this.totalQuantity = 0;
    this.totalPrice = 0;
    this.items.forEach((item) => {
      this.totalQuantity += item.quantity;
      this.totalPrice += +item.variant.price * item.quantity;
    });
    if (this.platformService.isServer) {
      return;
    }
    this.clearTimeout();
    this.updateTimeout = setTimeout(
      () => this.updatedSubject.next({ cart: this, type, message }),
      200
    );
  }

  private clearTimeout(): void {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  }

  public add(lineItem: ICartProductVariant): void {
    let title = 'No variant found';
    const inCart = this.items.find(
      (item) => item.variant.id === lineItem.variantId
    );
    if (inCart) {
      inCart.quantity += lineItem.quantity;
      title = inCart.title;
    } else {
      const product = this.productService.getProductById(lineItem.productId);
      const variant = product?.variants.find(
        (variant) => variant.id === lineItem.variantId
      );
      if (variant) {
        const product = this.productService.getProductById(variant.product_id);
        if (product) {
          title = product?.title;
          this.items.push(
            new FotverketProductVariant(variant, lineItem.quantity, product)
          );
        }
      }
    }
    this.updated(CartUpdated.ItemAdded, title);
  }

  public remove(variantIds: number[]): void {
    if (this.items.length > 0) {
      variantIds.forEach((id) => {
        const item = this.items.find((item) => item.variant.id === id);
        if (item) {
          const index = this.items.indexOf(item);
          this.items.splice(index, 1);
        }
      });
      this.updated(CartUpdated.ItemRemoved);
    }
  }

  public update(lineItem: ICartProductVariant): void {
    const item = this.items.find(
      (item) => item.variant.id === lineItem.variantId
    );
    if (item) {
      if (lineItem.quantity !== 0) {
        item.quantity = lineItem.quantity;
        this.updated(CartUpdated.QuantityChanged, item.title);
      } else {
        this.remove([item.variant.id]);
      }
    }
  }

  public clear(): void {
    this.items = [];
    this.updated(CartUpdated.Empty);
  }

  public generateCookie(): ICartCookie {
    return {
      cart: this.items.map(
        (product) =>
          ({
            productId: product.productId,
            variantId: product.variant.id,
            quantity: product.quantity,
          } as ICartProductVariant)
      ),
    };
  }
}
