<header>
  <nav #navbar class="navbar navbar-expand-md stroke flex center">
    <div class="flex space">
      <div class="flex center-vertical">
        @if (urlTreeHeight < 2) {
        <a class="navbar-brand" [routerLink]="['/']">
          <picture fetchpriority="high">
            <source
              srcset="../../assets/img/fotverket-logo.webp"
              type="image/webp"
            />
            <source
              srcset="../../assets/img/fotverket-logo.png"
              type="image/png"
            />
            <img
              #logo
              class="navbar-logo"
              src="../../assets/img/fotverket-logo.png"
              alt="Fotverket logotyp"
              loading="eager"
            />
          </picture>
        </a>
        } @else {
        <button
          #backBtn
          type="button"
          class="btn-back"
          title="{{ 'COMMON.BACK' | translate }}"
          (click)="navigationService.back()"
        >
          <i class="fa-solid fa-arrow-left fa-3x"></i>
        </button>
        }
        <core-product-autocomplete></core-product-autocomplete>
      </div>
      <div class="flex">
        <shopping-cart class="center-self"></shopping-cart>

        <button
          #menu
          [datacy]="'header-hamburger-menu'"
          class="navbar-toggler collapsed"
          type="button"
          data-target="#menuList"
          data-toggle="collapse"
          aria-label="Meny"
          title="Meny"
          (click)="menu.blur()"
        >
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>
        <div
          #menuList
          id="menuList"
          class="navbar-dropdown-menu navbar-collapse collapse flex desktop-right mobile-center fix-navbar-collapse"
          title="Meny"
        >
          <ul class="navbar-nav">
            @for (data of routes; track data.path) {
            <li class="nav-item flex center center-vertical">
              @if (data) {
              <button
                #navigationItem
                [datacy]="
                  'header-' + navigationItem.innerHTML.trim().replace(' ', '-')
                "
                class="nav-link"
                [class.active]="currentRoute === data['path']"
                [class.inactive]="currentRoute !== data['path']"
                [routerLink]="[data['path']]"
                type="menu"
                title="{{ navigationItem.innerHTML }}"
                (click)="navigationItem.blur()"
              >
                {{ data["name"] | translate }}
              </button>
              }
            </li>
            }
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
