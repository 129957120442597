import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '@standalone/base-component.component';

import { PlatformService } from '@core/services/platform.service';
import { ToastType, IToast, ToastService } from '@core/services/toast.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toast',
  template: `<div
    [class.show]="show"
    class="toast-modal flex center center-vertical"
    [class.info]="toast?.type === type.info"
    [class.success]="toast?.type === type.success"
    [class.warning]="toast?.type === type.warning"
    [class.danger]="toast?.type === type.danger"
  >
    <icon-button
      [datacy]="'toast-close'"
      [class]="'btn-close show flex center center-vertical'"
      (whenClick)="hideToast()"
      [title]="'COMMON.CLOSE' | translate : { text: 'toast' }"
    >
      <i class="fa-solid fa-x fa-lg"></i>
    </icon-button>
    <div class="toast-body flex">
      <span>{{ toast?.message }}</span>
    </div>
    <div class="progressBar" [ngStyle]="{ width: progress + '%' }"></div>
  </div>`,
  styles: [],
})
export class ToastComponent extends BaseComponent implements OnInit {
  protected type: typeof ToastType = ToastType;
  protected toast: IToast | null = null;
  protected show = false;
  protected progress: number = 0;
  private toastDuration: number = 0;
  private timers: NodeJS.Timeout[] = [];
  private interval: NodeJS.Timeout | undefined;
  constructor(
    private toastService: ToastService,
    private platformService: PlatformService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  protected hideToast(): void {
    this.clearTimers();
  }

  private clearTimers(): void {
    this.timers.forEach((t) => clearTimeout(t));
    clearInterval(this.interval);
    this.show = false;
    this.toast = null;
    this.progress = 0;
    this.cdr.detectChanges();
  }

  private showToast(): void {
    this.show = true;
    if (this.platformService.isServer) {
      return;
    }
    this.interval = setInterval(() => {
      ++this.progress;
      this.cdr.detectChanges();
    }, this.toastDuration / 100);
    this.timers.push(
      setTimeout(() => {
        this.show = false;
        this.cdr.detectChanges();
      }, this.toastDuration)
    );
    this.deleteToast();
  }

  private deleteToast(): void {
    this.timers.push(
      setTimeout(() => this.hideToast(), this.toastDuration * 1.01)
    );
  }

  ngOnInit(): void {
    this.toastService.toast$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (toast) => {
        this.hideToast();

        if (toast) {
          this.toast = toast;

          this.toastDuration = toast.duration;
          this.showToast();
        }
      },
    });
  }
}
