import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormsModule } from '@angular/forms';
import { KBCode } from 'server/constants';
import { BaseComponent } from '@standalone/base-component.component';
import { ButtonModule } from '@features/button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'item-amount',
  template: ` <div
    class="add-wrapper flex center-vertical"
    [class.small]="small"
  >
    <icon-button
      [class]="small ? 'btn-add small show' : 'btn-add show'"
      (whenClick)="add(-1)"
      [title]="'COMMON.REDUCE' | translate"
      ><i class="fa-solid fa-minus"></i
    ></icon-button>
    <input
      #input
      [(ngModel)]="amount"
      type="number"
      min="0"
      [max]="max"
      class="item-amount"
      (click)="input.select()"
      (blur)="add(0)"
      [class.small]="small"
    />
    <icon-button
      [class]="small ? 'btn-add small show' : 'btn-add show'"
      [class.small]="small"
      (whenClick)="add(1)"
      [title]="'COMMON.INCREASE' | translate"
      ><i class="fa-solid fa-plus"></i
    ></icon-button>
  </div>`,
  styles: [
    `
      .add-wrapper {
        &.small {
          margin-bottom: 0.5rem;
        }
      }
      .item-amount {
        width: 50px;
        height: 40px;
        font-size: xx-large;
        margin: 0px 10px 0px 10px;
        padding: 0;
        text-align: center;
        border: none;
        &.small {
          height: 20px;
          font-size: medium;
          width: 30px;
        }
      }
    `,
  ],
  standalone: true,
  imports: [ButtonModule, FormsModule, TranslateModule],
})
export class ItemAmountComponent
  extends BaseComponent
  implements AfterViewInit
{
  protected amount: number = 1;
  @Input() public small: boolean = false;
  private destroyKeyDown!: () => void;
  @Input() public set quantity(value: number) {
    this.amount = value;
  }
  @Output() public amountChanged = new EventEmitter<number>();
  @Input() public formcontrol?: AbstractControl<number>;
  @Input() public max: number = 99;
  @Input() public allowZero: boolean = false;
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) {
    super();
  }
  override ngOnDestroy(): void {
    this.destroyKeyDown();
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.destroyKeyDown = this.renderer.listen(
      this.input.nativeElement,
      'keydown',
      (keyEvent: KeyboardEvent) => {
        switch (keyEvent.code) {
          case KBCode.Enter:
            keyEvent.preventDefault();
            this.input.nativeElement.blur();
            break;
          case KBCode.ArrowDown:
            keyEvent.preventDefault();
            this.add(-1, false);
            break;
          case KBCode.ArrowUp:
            keyEvent.preventDefault();
            this.add(1, false);
            break;
          default:
            break;
        }
        if (keyEvent.defaultPrevented) {
          this.cdr.detectChanges();
        }
      }
    );
  }

  protected add(value: number, emit: boolean = true): void {
    if (this.amount + value < 1 && this.allowZero) {
      this.amount = 0;
    } else if (this.amount + value < 1) {
      this.amount = this.max === 0 ? 0 : 1;
    } else if (this.amount + value > this.max) {
      this.amount = this.max;
    } else {
      this.amount += value;
    }
    if (!emit) {
      return;
    }
    this.emitValue();
  }

  private emitValue(): void {
    if (this.formcontrol) {
      this.formcontrol.patchValue(this.amount);
    }
    this.amountChanged.emit(this.amount);
    this.cdr.detectChanges();
  }
}
