import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';
import { ICallbackNoParam } from '@shared/utilities/Subscribe.functions';
import { PlatformService } from '@core/services/platform.service';
import { EventType, LoggingService } from '@core/services/logging.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'autocomplete',
  template: `<div class="autocomplete-container">
      <input
        #input
        [name]="placeholder"
        class="autocomplete"
        type="search"
        (keyup)="filterItems(input.value)"
        (focus)="filterItems(input.value)"
        (search)="filterItems(input.value)"
        (keydown.enter)="selectFirstItem()"
        (keydown.arrowUp)="moveToSuggestionBox($event)"
        (keydown.arrowDown)="moveToSuggestionBox($event)"
        placeholder="{{
          placeholder ? placeholder : ('COMMON.SEARCH' | translate)
        }}"
        title="{{ input.placeholder }}"
        [class.visible]="filteredItems.length > 0"
      />
      @if (filteredItems.length) {
      <div #suggestionBox class="suggestion-box flex vertical">
        <ul #itemList>
          @for (item of filteredItems; track item.id; let i = $index) {
          <li
            (click)="select(item.id)"
            (keydown.enter)="select(item.id)"
            (keydown.arrowDown)="moveDownList($event, i)"
            (keydown.arrowUp)="moveUpList($event, i)"
            tabindex="0"
            class="is-clickable"
            title="{{
              'AUTOCOMPLETE.LIST.TITLE'
                | translate
                  : {
                      text: sanitizeText(item.text),
                      index: i + 1,
                      totalCount: filteredItems.length
                    }
            }}"
          >
            <ng-template
              [ngTemplateOutlet]="template ? template : standard"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-template>
          </li>
          }
        </ul>
      </div>
      }
    </div>

    <ng-template #standard let-item>
      <a>{{ item.text }}</a>
    </ng-template>`,
  styles: [
    `
      @import '/src/styles/variables';
      .autocomplete-container {
        position: relative;

        .suggestion-box {
          position: absolute;
          top: 44px;
          background-color: $secondary;
          width: $inputWidth;
          max-height: 50vh;
          overflow-y: auto;
          padding: $padding;
          border-radius: 7px;
          font-weight: 400;
          -webkit-box-shadow: -1px 10px 25px -18px rgb(0 0 0 / 49%);
          -moz-box-shadow: -1px 10px 25px -18px rgba(0, 0, 0, 0.49);
          box-shadow: -1px 10px 25px -18px rgb(0 0 0 / 49%);
          letter-spacing: normal;
          text-transform: none;

          > ul {
            margin-block: 0px;
            padding-inline: 0px;

            > li:not(:last-child) {
              margin-bottom: 20px;
            }

            > li {
              border-radius: 7px;
              padding: 4px;
              transition: all 0.1s;
              &:focus-visible,
              &:hover {
                color: $secondary;
                border: none !important;
                outline: none !important;
                background: $primary;
                img {
                  transform: scale(1.5);
                }
              }
            }
          }
        }

        .autocomplete-visible {
          border: 2px solid $primary !important;
          outline: 0px;
          width: $inputWidth !important;
        }

        input.autocomplete {
          width: 68px;
          padding: $padding;
          border: 2px solid black;
          border-radius: 7px;
          transition: all 0.5s;

          &:focus-visible {
            @extend .autocomplete-visible;
          }

          &.visible {
            @extend .autocomplete-visible;
          }

          &[type='search' i]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
            cursor: pointer;
          }
        }
      }
    `,
  ],
})
export class AutocompleteComponent<TClass> extends BaseComponent {
  public filteredItems: ISelectable<TClass>[] = [];
  private selectables: ISelectable<TClass>[] = [];
  private destroyClickListner: ICallbackNoParam = () => void 0;
  @Input() public template?: TemplateRef<TClass>;
  @Input() public placeholder?: string;
  @Input() public set items(value: ISelectable<TClass>[]) {
    this.selectables = value;
  }
  @Output() itemSelected = new EventEmitter<TClass>();
  @ViewChild('input') public input?: ElementRef<HTMLInputElement>;
  @ViewChild('suggestionBox')
  private suggestionBox?: ElementRef<HTMLDivElement>;
  @ViewChild('itemList')
  private itemList?: ElementRef<HTMLUListElement>;

  constructor(
    private renderer: Renderer2,
    private platformService: PlatformService,
    private loggingService: LoggingService
  ) {
    super();
    this.clickToCloseListener();
  }

  // filter items and sort the result with the highest score first
  public filterItems(value: string): void {
    this.filteredItems = this.selectables
      .filter((x) => this.filterBy(value.trim(), x))
      .sort((a, b) => ((a.score ?? 0) > (b.score ?? 0) ? -1 : 1));
  }

  private filterBy(value: string, object: ISelectable<TClass>): boolean {
    this.clearText(object);
    const searchPattern = new RegExp(this.buildRegexSearchpattern(value), 'i');
    const result =
      this.isAboveRequiredLength(value) &&
      (searchPattern.test(object.text) || searchPattern.test(object.metaData));

    if (result) {
      this.highlightAndScoreSearch(value, object);
    }

    return result;
  }

  private highlightAndScoreSearch(
    value: string,
    object: ISelectable<TClass>
  ): void {
    let regexstring = new RegExp(this.buildRegexSearchpattern(value), 'ig');
    const textResult = object.text.match(regexstring);
    const metaDataResult = object.metaData.match(regexstring);
    const searchResults = [
      ...new Set((textResult ?? ['']).concat(metaDataResult ?? [])),
    ].filter((x) => x !== '');
    let score = 0;
    searchResults.forEach((match) => {
      score += match.length;
      const pattern = new RegExp('(?<!<\\/?\\w*)' + match + '(?!\\w*>)', 'ig');
      object.text = object.text.replace(pattern, `<strong>${match}</strong>`);
    });
    object.score = score;
  }

  private buildRegexSearchpattern(value: string): string {
    let searchPattern = '';
    const values = value
      .split(' ')
      .filter((x) => x !== '' && this.isAboveRequiredLength(x));
    values.forEach(
      (v) =>
        (searchPattern +=
          values.indexOf(v) === values.length - 1
            ? this.searchPattern(v)
            : `${this.searchPattern(v)}|`)
    );

    return searchPattern;
  }

  private isAboveRequiredLength(value: string): boolean {
    return value.length >= 3;
  }

  private searchPattern(value: string): string {
    if (value.length <= 3) {
      return value;
    }
    const index = Math.round(value.length * 0.8);
    const firstPart = value.substring(0, index);
    let secondPart = '';
    const array = value.substring(index, value.length).split('');
    array.forEach((v) => (secondPart += `${v}?`));

    const result = firstPart + secondPart;
    return result;
  }

  private clearText(object: ISelectable<TClass>): void {
    const htmlRegex = new RegExp(/(<([^>]+)>)/gi);
    object.text = object.text.replace(htmlRegex, '');
    object.score = undefined;
  }

  public select(id: string) {
    const selectedItem = this.selectables.find((x) => x.id === id);
    const object = selectedItem?.object;
    if (object) {
      this.loggingService.logEvent('searchItemSelected', EventType.click, {
        searchTerm: this.input?.nativeElement.value,
        resultCount: this.filteredItems.length,
        selectedItemText: selectedItem.text,
        selectedItemIndex: this.filteredItems.indexOf(selectedItem),
      });
      this.clearInputAndList();
      this.itemSelected.emit(object);
    }
  }

  private clickToCloseListener(): void {
    this.destroyClickListner();
    this.destroyClickListner = this.renderer.listen(
      'window',
      'click',
      (e: Event) => {
        if (
          e.target !== this.input?.nativeElement &&
          e.target !== this.suggestionBox?.nativeElement
        ) {
          this.clearInputAndList(false);
        }
      }
    );
  }

  protected selectFirstItem(): void {
    const selectedItem = this.filteredItems[0];
    if (selectedItem) {
      this.select(selectedItem.id);
    }
  }

  private clearInputAndList(clearInput: boolean = true): void {
    this.input?.nativeElement && clearInput
      ? (this.input.nativeElement.value = '')
      : null;
    this.filteredItems = [];
  }

  public focus(): void {
    if (this.platformService.isServer) {
      return;
    }
    setTimeout(() => {
      this.input?.nativeElement.focus();
    }, 100);
  }

  protected moveToSuggestionBox(event: Event): void {
    event.preventDefault();
    const keyEvent = event as KeyboardEvent;
    if (this.filteredItems.length > 0) {
      const index =
        keyEvent.key === 'ArrowUp' ? this.filteredItems.length - 1 : 0;
      (
        this.itemList?.nativeElement.children.item(index) as HTMLDataListElement
      )?.focus();
    }
  }

  protected moveDownList(event: Event, index: number): void {
    this.navigateSuggestions(
      event,
      index === this.filteredItems.length - 1,
      index + 1
    );
  }

  protected moveUpList(event: Event, index: number): void {
    this.navigateSuggestions(event, index === 0, index - 1);
  }

  private navigateSuggestions(
    event: Event,
    moveToInput: boolean,
    index: number
  ): void {
    event.preventDefault();
    if (moveToInput) {
      this.input?.nativeElement.focus();
    }
    const nextItem = this.itemList?.nativeElement.children.item(
      index
    ) as HTMLLIElement;
    if (nextItem) {
      nextItem.focus();
    }
  }

  protected sanitizeText(text: string): string {
    return text.replace(/(<([^>]+)>)/gi, '');
  }

  override ngOnDestroy(): void {
    this.destroyClickListner();
    super.ngOnDestroy();
  }
}

export interface ISelectable<TClass> {
  object: TClass;
  id: string;
  text: string;
  metaData: string;
  imgSrc?: string;
  imgAlt?: string;
  score?: number;
}
