import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@standalone/base-component.component';

import { NavigationService } from '@core/services/navigation.service';
import { DomService } from '@core/services/dom.service';
import { PlatformService } from '@core/services/platform.service';
import { Location } from '@angular/common';
import { environment } from '@environment/environment';
import { CookieConsentService } from '@core/services/cookie-consent.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @ViewChild('navbar', { static: true })
  private navbar: ElementRef<HTMLElement> = {} as ElementRef<HTMLElement>;
  @ViewChild('logo') private set setLogo(value: ElementRef<HTMLImageElement>) {
    this.logo = value;
  }
  private logo: ElementRef<HTMLImageElement> | undefined;
  @ViewChild('backBtn') private set setBackBtn(
    value: ElementRef<HTMLButtonElement>
  ) {
    this.backBtn = value;
  }
  private backBtn: ElementRef<HTMLButtonElement> | undefined;
  @ViewChild('menu', { static: true }) private menu: ElementRef<HTMLElement> =
    {} as ElementRef<HTMLElement>;
  @ViewChild('menuList', { static: true })
  private menuList: ElementRef<HTMLElement> = {} as ElementRef<HTMLElement>;
  protected prevScrollpos: number =
    this.domService.document.defaultView?.scrollY ?? 0;
  protected routes: Array<{ name: string; path: string }> = [];
  protected imgLogo: string = '';
  protected urlTreeHeight: number = 0;

  @HostListener('document:scroll') public onScroll() {
    if (this.platformService.isBrowser) {
      this.navbarResize();
      this.setMenuHeight();
      this.closeMenuOnScroll();
      this.cdr.detectChanges();
    }
  }
  @HostListener('document:click') public onClick() {
    const opened = this.menuList.nativeElement.classList.contains('show');
    if (opened) {
      this.menu.nativeElement.click();
    }
  }
  public currentRoute: string = '';
  private preHeaderHeight: number = 42;
  private maxHeaderHeight: number = 107;
  private minHeaderHeight: number = 78;
  protected canBlur: boolean = true;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    protected navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private cookieConsentService: CookieConsentService,
    private platformService: PlatformService,
    private domService: DomService,
    protected loc: Location
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.platformService.isBrowser) {
      this.changeNavbarHeight(this.maxHeaderHeight);
      this.setMenuHeight();
      this.cookieConsentService.cookie$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (cookie) => {
            if (cookie) {
              this.canBlur = false;
              document
                .getElementsByTagName('header')[0]
                ?.classList.remove('blur');
            }
            this.cdr.detectChanges();
          },
        });
    }
    this.routes =
      this.router.config
        .filter((r) => r.data && !r.data['skip'])
        .map((r) => ({
          name: r.data ? r.data['name'] : '',
          path: r.path ? r.path : '',
        })) ?? [];

    this.navigationService.routeData$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          const regex = new RegExp(`\\/`, 'gi');
          const match = this.router.url.match(regex);
          this.urlTreeHeight = match?.length ?? 0;
          this.currentRoute =
            this.router.url.split('/')[this.router.url.split('/').length - 1];
          this.cdr.detectChanges();
        },
      });
  }

  private changeNavbarHeight(height: number): void {
    if (this.platformService.isBrowser) {
      this.domService.document.body.style.setProperty(
        '--navbarHeight',
        `${height}px`
      );
    }
  }

  private closeMenuOnScroll(): void {
    let currentScrollPos = this.domService.document.defaultView?.scrollY ?? 0;
    if (this.prevScrollpos < currentScrollPos) {
      const _opened = this.menuList.nativeElement.classList.contains('show');
      if (_opened) {
        this.menu.nativeElement.click();
      }
    }

    this.prevScrollpos = currentScrollPos;
  }

  private navbarResize() {
    if (
      this.domService.document.documentElement.scrollTop >=
        this.preHeaderHeight ||
      this.domService.document.body.scrollTop >= this.preHeaderHeight
    ) {
      this.logo?.nativeElement.classList.add('small');
      this.backBtn?.nativeElement.classList.add('small');
      this.navbar.nativeElement.classList.add('shadow-sm');
      this.changeNavbarHeight(this.minHeaderHeight);
      return;
    }

    this.logo?.nativeElement.classList.remove('small');
    this.backBtn?.nativeElement.classList.remove('small');
    this.navbar.nativeElement.classList.remove('shadow-sm');
    this.changeNavbarHeight(this.maxHeaderHeight);
  }
  private setMenuHeight() {
    if (this.platformService.isBrowser) {
      const value =
        this.domService.document.body.style.getPropertyValue('--navbarHeight');
      const thing = +value.slice(0, value.length - 2);
      const fortyTwo = 42;
      const height =
        (this.domService.window?.scrollY ?? fortyTwo) <= fortyTwo
          ? (this.domService.window?.scrollY ?? fortyTwo) !== 0
            ? fortyTwo - (this.domService.window?.scrollY ?? fortyTwo) + thing
            : thing + fortyTwo
          : thing;
      this.domService.document.body.style.setProperty(
        '--menuHeight',
        `${height}px`
      );
    }
  }
}
