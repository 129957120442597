import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { DomService } from '@core/services/dom.service';
import { environment } from '@src/environments/environment';
import { BaseComponent } from '@standalone/base-component.component';

@Component({
  selector: 'core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends BaseComponent {
  protected year: number = new Date().getFullYear();
  protected contactInfo = environment.contactInfo;
  protected widgetLoaded = false;
  @ViewChild('trustbox') protected set trustbox(
    value: ElementRef<HTMLDivElement>
  ) {
    const trustpilot = (this.domService.window as any)?.Trustpilot;
    if (value && trustpilot && !this.widgetLoaded) {
      trustpilot.loadFromElement(value.nativeElement);
      this.widgetLoaded = true;
      this.cdr.detectChanges();
    }
  }
  constructor(private domService: DomService, private cdr: ChangeDetectorRef) {
    super();
  }
}
