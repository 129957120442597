import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomService } from '@core/services/dom.service';
import { NavigationService } from '@core/services/navigation.service';
import { PlatformService } from '@core/services/platform.service';
import { ToastService } from '@core/services/toast.service';
import { ICallbackNoParam } from '@shared/utilities/Subscribe.functions';
import { ModalComponent } from '../modal/modal.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dropdown-modal',
  template: `@if (display) {
    <div
      #modal
      class="dropdown-modal flex vertical right center center-vertical"
      [class.close]="!(open$ | async)"
      [class.open]="open$ | async"
    >
      <icon-button
        [class]="'btn-close show flex center center-vertical'"
        (whenClick)="closeWithDelay()"
        [title]="
          'COMMON.CLOSE' | translate : { text: 'COMMON.WINDOW' | translate }
        "
      >
        <i class="fa-solid fa-x fa-lg"></i>
      </icon-button>
      <ng-content></ng-content>
    </div>
    }`,
  styles: [],
})
export class DropdownModalComponent extends ModalComponent {
  @ViewChild('modal') public modal?: ElementRef;
  @Input() public clickOrigin?: HTMLElement;
  private destroyClickListner: ICallbackNoParam = () => void 0;
  private openSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    protected renderer: Renderer2,
    toastService: ToastService,
    navigationService: NavigationService,
    domService: DomService,
    platformService: PlatformService,
    cdr: ChangeDetectorRef
  ) {
    super(toastService, navigationService, domService, platformService, cdr);
  }

  protected closeWithDelay(): void {
    this.openSubject.next(false);
    this.destroyClickListner ? this.destroyClickListner() : null;
    if (this.platformService.isServer) {
      return;
    }
    setTimeout(() => {
      super.displayModal(false);
    }, 100);
  }

  public override displayModal(value: boolean): void {
    if (value) {
      super.displayModal(value);
      this.openSubject.next(true);
      if (this.platformService.isServer) {
        return;
      }
      setTimeout(() => {
        this.clickToCloseListener();
      }, 1);
    } else {
      this.closeWithDelay();
    }
    this.cdr.detectChanges();
  }

  private clickToCloseListener(): void {
    this.destroyClickListner();
    this.destroyClickListner = this.renderer.listen(
      'window',
      'click',
      (e: Event) => {
        if (
          this.modal &&
          e.target !== this.modal.nativeElement &&
          !this.modal.nativeElement.contains(e.target)
        ) {
          this.closeWithDelay();
        }
      }
    );
  }

  public get open$(): Observable<boolean> {
    return this.openSubject.asObservable();
  }

  override ngOnDestroy(): void {
    this.destroyClickListner();
    super.ngOnDestroy();
  }
}
