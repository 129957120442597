import {
  Inject,
  Injectable,
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { BaseApiClientService } from '@shared/services/base-api-client.service';
import { CookieService } from './cookie.service';
import { IData } from 'shared/interfaces';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseApiClientService {
  private stateKey: StateKey<IData<string>>;
  constructor(
    private state: TransferState,
    private cookieService: CookieService,
    private platformSerice: PlatformService
  ) {
    super('authentication');
    this.stateKey = makeStateKey<IData<string>>('dTO0l4yn/7YxUG');
    if (!this.state.hasKey(this.stateKey) && this.platformSerice.isServer) {
      this.authenticate();
    } else {
      this.setCookie(this.state.get(this.stateKey, { name: '', value: '' }));
    }
  }
  private authenticate() {
    this.get<IData<string>>().subscribe((data) => {
      this.state.set(this.stateKey, data);
    });
  }

  private setCookie(data: IData<string>): void {
    this.cookieService.set(data.name, data.value, undefined, '/');
  }
}
