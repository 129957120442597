import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingCartComponent } from './shopping-cart.component';
import { CartItemComponent as ShoppingCartItemComponent } from './cart-item/cart-item.component';
import { ModalModule } from '@modal/modal.module';
import { ButtonModule } from '@features/button/button.module';
import { LoadingModule } from '@features/loading/loading.module';
import { RouterModule } from '@angular/router';
import { SkeletonComponent } from '@standalone/components/skeleton/skeleton.component';
import { ItemAmountComponent } from '@standalone/components/item-amount/item-amount.component';
import { TranslateModule } from '@ngx-translate/core';
import { DataCyDirective } from '@standalone/directives/data-cy.directive';

@NgModule({
  declarations: [ShoppingCartComponent, ShoppingCartItemComponent],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    LoadingModule,
    RouterModule,
    SkeletonComponent,
    ItemAmountComponent,
    TranslateModule,
    DataCyDirective,
  ],
  exports: [ShoppingCartComponent, ShoppingCartItemComponent],
})
export class ShoppingCartModule {}
