import { Injectable } from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';
import { BehaviorSubject, Observable, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService extends BaseComponent {
  public loadingSubject: BehaviorSubject<ILoading | null> =
    new BehaviorSubject<ILoading | null>(null);
  private loadingQueue: string[] = [];
  constructor() {
    super();
  }

  public get loading$(): Observable<ILoading | null> {
    return this.loadingSubject.asObservable().pipe(debounceTime(200));
  }

  public increaseLoading(message?: string): void {
    this.loadingQueue.push(message ?? '');
    this.update(message);
  }

  public decreaseLoading(): void {
    if (this.loadingQueue.length === 0) {
      this.update();
      return;
    }

    const message = this.loadingQueue.pop();
    this.update(message);
  }

  private update(message?: string): void {
    const status =
      this.loadingQueue.length > 0
        ? {
            loading: this.loadingQueue.length > 0,
            message: message ?? '',
          }
        : null;
    this.loadingSubject.next(status);
  }

  public stopLoading(): void {
    this.loadingQueue = [];
    this.update();
  }
}

export interface ILoading {
  loading: boolean;
  message: string;
}
