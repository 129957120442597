import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BaseComponent } from '@standalone/base-component.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'icon-button',
  template: `<button
    #iconButton
    unFocus
    type="button"
    title="{{ title }}"
    class="btn-shortcut {{ class }}"
    tabindex="{{ tab ? '0' : '-1' }}"
    (click)="clicked($event)"
  >
    <ng-content></ng-content>
  </button> `,
  styles: [],
})
export class IconButtonComponent extends BaseComponent {
  @Input() public class: string = '';
  @Input() public title: string = '';
  @Input() public tab: boolean = true;
  @Output() whenClick: EventEmitter<Event> = new EventEmitter();

  constructor() {
    super();
  }

  protected clicked(clickEvent: Event): void {
    this.whenClick.emit(clickEvent);
  }
}
