import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@standalone/base-component.component';
import { BehaviorSubject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { NavigationService } from '@core/services/navigation.service';
import { PopupModalComponent } from '@modal/components/popup-modal/popup-modal.component';
import { PlatformService } from '@core/services/platform.service';
import { DomService } from '@core/services/dom.service';
import {
  CookieConsentService,
  IFotverketCookie,
} from '@core/services/cookie-consent.service';
import { ToastService } from '@core/services/toast.service';
import { EventType, LoggingService } from '@core/services/logging.service';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styles: [
    `
      @import '/src/styles/variables';
      .cookie-container {
        @media (min-width: $mobileBreakpoint) {
          max-width: 50%;
        }

        @media (min-width: 1600px) {
          max-width: 25%;
        }
      }
      .cookie-form {
        width: 100%;
        padding: 20px 10px 20px 10px;
        overflow: auto;
        max-height: calc(100vh - $navbarMaxHeight);
      }
      .form-group {
        margin-bottom: 23px;
        &.is-invalid {
          margin-bottom: 0px;
        }
      }
    `,
  ],
})
export class CookieConsentComponent extends BaseComponent implements OnInit {
  @ViewChild('consentModal') private consentModal: PopupModalComponent =
    new PopupModalComponent(
      this.renderer,
      this.toastService,
      this.navigationService,
      this.domService,
      this.platformService,
      this.cdr
    );
  public show$ = new BehaviorSubject<boolean>(false);
  private hasConsented: boolean = false;
  protected cookieForm: UntypedFormGroup = this.cookieConsentService.cookieForm;
  protected showNecessary: boolean = false;
  protected showStatistical: boolean = false;
  private hasAsked: boolean = false;

  constructor(
    private cookieConsentService: CookieConsentService,
    protected renderer: Renderer2,
    protected toastService: ToastService,
    protected navigationService: NavigationService,
    private domService: DomService,
    private platformService: PlatformService,
    private cdr: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.platformService.isServer) {
      return;
    }
    this.cookieConsentService.cookie$
      .pipe(takeUntil(this.ngUnsubscribe), delay(400))
      .subscribe({
        next: (cookie) => {
          this.hasConsented = !!cookie || this.hasAsked;
          this.show$.next(!this.hasConsented);
          this.cdr.detectChanges();
        },
      });

    this.show$
      .asObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (show) => {
          this.consentModal.displayModal(show);
          this.cdr.detectChanges();
        },
      });
  }

  public setCookies(value: boolean | null): void {
    if (value === null) {
      this.cookieConsentService.removeCookies();
      this.consentModal.displayModal(false);
      this.setAllCookieValues(false);
      this.hasAsked = true;
      return;
    }

    if (!!value) {
      this.setAllCookieValues(value);
    }

    if (this.cookieForm.valid) {
      const cookie = this.cookieForm.value as IFotverketCookie;
      this.cookieConsentService.setCookie(cookie);
      this.loggingService.logEvent('cookieConsent', EventType.click, {
        ...cookie,
      });
    }
  }

  private setAllCookieValues(value: boolean): void {
    Object.keys(this.cookieForm.controls).forEach((key) => {
      const control = this.cookieForm.get(key);
      if (control && !control.hasValidator(Validators.requiredTrue)) {
        control.patchValue(value);
      }
    });
  }
}
