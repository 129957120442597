import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ICartVariant } from '../shopping-cart.component';
import { ProductService } from '@core/services/product.service';
import { ShoppingCartService } from '@core/services/shopping-cart.service';
import { ICartProductVariant } from 'server/interfaces/fotverket-interface';
import { BaseComponent } from '@standalone/base-component.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent extends BaseComponent {
  @Input() public lineItem!: ICartVariant;
  constructor(
    private router: Router,
    private cartService: ShoppingCartService,
    protected productService: ProductService
  ) {
    super();
  }

  protected deleteCartItem() {
    const id = this.lineItem?.variantId;
    if (id) {
      this.cartService.removeLineItem([id]);
    }
  }

  protected routeToProductPage(): void {
    let queryParams: Params = {};
    this.lineItem?.selectedOptions.forEach((option) => {
      queryParams[option.key] = option.value;
    });
    this.router.navigate(['produkter', this.lineItem?.handle], {
      queryParams: queryParams,
    });
  }

  protected updateLineItem(quantity: number): void {
    if (quantity === 0) {
      this.deleteCartItem();
    } else {
      const update = {
        quantity: quantity,
        variantId: this.lineItem.variantId,
        productId: this.lineItem.productId,
      } as ICartProductVariant;
      this.cartService.updateLineItems(update);
    }
  }
}
