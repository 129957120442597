import { Injectable, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  DistributedTracingModes,
  ITelemetryItem,
  LoggingSeverity,
  PageView,
  Snippet,
} from '@microsoft/applicationinsights-web';
import { PlatformService } from '@core/services/platform.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '@standalone/base-component.component';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService extends BaseComponent {
  private appInsights: ApplicationInsights | undefined;
  private cookies: string[] = ['ai_user', 'ai_session'];
  constructor(
    private router: Router,
    private platformService: PlatformService
  ) {
    super();
  }

  public initialize(): void {
    if (this.appInsights || this.platformService.isServer) {
      return;
    }
    inject(NgZone).runOutsideAngular(() => {
      this.appInsights = new ApplicationInsights(this.config);
      this.appInsights.core.getCookieMgr().setEnabled(false);
      this.addTelemetryInitializers();
      this.appInsights.loadAppInsights();
    });
  }

  public enableCookies(enable: boolean = true): void {
    const cookieManager = this.appInsights?.core.getCookieMgr();
    if (!enable) {
      this.cookies.forEach((cookie) => cookieManager?.del(cookie));
    }
    cookieManager?.setEnabled(enable);
  }

  private get config(): Snippet {
    const angularPlugin = new AngularPlugin();
    return {
      config: {
        instrumentationKey: environment.appInsights.config.instrumentationKey,
        enableAutoRouteTracking: false,
        autoTrackPageVisitTime: false,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
        disableCookiesUsage: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.AI,
        correlationHeaderExcludedDomains: ['formsubmit.co', '*.myshopify.com'],
      },
    };
  }

  private addTelemetryInitializers() {
    this.telemetryInitializers.forEach((initializer) =>
      this.appInsights?.addTelemetryInitializer(initializer)
    );
  }

  trackPageView(pageViewTelemetry: IPageViewTelemetry) {
    this.appInsights?.trackPageView(pageViewTelemetry);
  }

  logEvent(eventTelemetry: IEventTelemetry) {
    if (!eventTelemetry.properties) {
      eventTelemetry.properties = {
        eventType: eventTelemetry.type,
      } as ICustomProperties;
    } else {
      eventTelemetry.properties['eventType'] = eventTelemetry.type;
    }

    this.appInsights?.trackEvent(
      { name: eventTelemetry.name },
      eventTelemetry.properties
    );
  }

  logMetric(metricTelemetry: IMetricTelemetry) {
    this.appInsights?.trackMetric(
      { name: metricTelemetry.name, average: metricTelemetry.average },
      metricTelemetry.properties
    );
  }

  logException(exceptionTlemetry: IExceptionTelemetry) {
    this.appInsights?.trackException(exceptionTlemetry);
  }

  logTrace(traceTelemetry: ITraceTelemetry) {
    this.appInsights?.trackTrace(traceTelemetry, traceTelemetry.properties);
  }

  public get telemetryInitializers(): ((
    item: ITelemetryItem
  ) => boolean | void)[] {
    return [this.baseInitializer, this.screenSizeInitializer];
  }

  private get baseInitializer(): (item: ITelemetryItem) => boolean | void {
    return (envelope: ITelemetryItem) => {
      let tags = envelope.tags;
      if (tags) {
        tags = tags || [];
        tags['ai.cloud.role'] = environment.appInsights.roleName;
      }
    };
  }

  private get screenSizeInitializer(): (
    item: ITelemetryItem
  ) => boolean | void {
    return (envelope: ITelemetryItem) => {
      if (envelope.name === PageView.envelopeType) {
        if (!envelope.data) {
          envelope.data = {
            screenHeight: screen.height,
            screenWidth: screen.width,
          };
        } else {
          envelope.data['screenWidth'] = screen.width;
          envelope.data['screenHeight'] = screen.height;
        }
      }
    };
  }
}

export enum EventType {
  click = 'click',
  formSubmit = 'formSubmit',
  conversion = 'conversion',
  addToCart = 'addToCart',
}

export interface ICustomProperties {
  [key: string]: any;
}
export interface IExceptionTelemetry {
  id?: string;
  exception: Error;
  severityLevel?: number;
}

export interface IEventTelemetry {
  name: string;
  type: EventType;
  properties?: ICustomProperties;
}

export interface IMetricTelemetry {
  name: string;
  average: number;
  properties?: ICustomProperties;
}

export interface IPageViewTelemetry {
  name: string;
  uri: string;
  isLoggedIn?: boolean;
  properties?: {
    duration?: number;
    [key: string]: any;
  };
}

export interface ITraceTelemetry {
  message: string;
  severityLevel: LoggingSeverity;
  properties?: ICustomProperties;
}

export enum SeverityLevel {
  crit = 4, // AI 'Critical'
  error = 3, // AI 'Error'
  warning = 2, // AI 'Warning'
  information = 1, // AI 'Info'
}
