import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { DomService } from '@core/services/dom.service';
import { PlatformService } from '@core/services/platform.service';
import { StructuredDataFactoryService } from '@core/services/structured-data-factory.service';
import { BaseComponent } from '@standalone/base-component.component';
import { takeUntil } from 'rxjs';
import { Thing, WithContext } from 'schema-dts';

@Component({
  selector: 'structured-data',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructuredDataComponent<
  TSchema extends Thing
> extends BaseComponent {
  private readonly id = 'StructuredData';
  private readonly structuredData: Map<
    string | number,
    WithContext<TSchema> | WithContext<TSchema>[]
  > = new Map();
  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private domService: DomService,
    private platformService: PlatformService,
    structuredDataFactoryService: StructuredDataFactoryService
  ) {
    super();
    structuredDataFactoryService.updated$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.add(data));
    structuredDataFactoryService.removed$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((id) => this.remove(id));
    if (this.platformService.isBrowser) {
      return;
    }

    this.createScript();
  }

  private createScript(): HTMLScriptElement {
    const script = this.getScript();
    if (script) {
      return script;
    }
    const newScript = this.renderer.createElement(
      'script'
    ) as HTMLScriptElement;
    newScript.type = 'application/ld+json';
    newScript.classList.add(this.id);
    this.domService.document.head.appendChild(newScript);
    return newScript;
  }

  private add(data: IStructuredData<TSchema>): void {
    this.structuredData.set(data.id, data.data);
    this.createLdJson();
  }

  private remove(id: string | number): void {
    this.structuredData.delete(id);
    this.createLdJson();
  }

  private createLdJson() {
    let script = this.getScript();
    if (!script) {
      script = this.createScript();
    }
    let values: TSchema[] = [];
    this.structuredData.forEach((v, k) => {
      if (v instanceof Array) {
        values = values.concat(v);
      } else {
        values.push(v);
      }
    });
    script.innerText = JSON.stringify(values);
    this.cdr.detectChanges();
  }

  private getScript(): HTMLScriptElement | undefined {
    const scripts = this.domService.document.head.getElementsByClassName(
      this.id
    ) as HTMLCollectionOf<HTMLScriptElement>;
    return scripts.length ? scripts[0] : undefined;
  }
}

export interface IStructuredData<TSchema extends Thing> {
  data: WithContext<TSchema> | WithContext<TSchema>[];
  id: string | number;
}
