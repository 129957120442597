import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[unFocus]',
  standalone: true,
})
export class UnFocusDirective implements OnInit, OnDestroy {
  private destroyTouchStart!: () => void;
  private destroyTouchEnd!: () => void;
  constructor(
    private element: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}
  ngOnDestroy(): void {
    this.destroyTouchStart();
    this.destroyTouchEnd();
  }
  ngOnInit(): void {
    this.destroyTouchStart = this.renderer.listen(
      this.element.nativeElement,
      'touchstart',
      () => {
        this.element.nativeElement.classList.add('touched', 'no-hover');
        this.cdr.detectChanges();
      }
    );
    this.destroyTouchEnd = this.renderer.listen(
      this.element.nativeElement,
      'touchend',
      () => {
        this.element.nativeElement.classList.remove('touched');
        this.element.nativeElement.blur();
        this.cdr.detectChanges();
      }
    );
  }
}
