import { NgModule } from '@angular/core';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ButtonComponent } from './components/button/button.component';
import { RouterModule } from '@angular/router';
import { DataCyDirective } from '@standalone/directives/data-cy.directive';
import { UnFocusDirective } from '@standalone/directives/un-focus.directive';

@NgModule({
  declarations: [IconButtonComponent, ButtonComponent],
  exports: [IconButtonComponent, ButtonComponent],
  imports: [RouterModule, DataCyDirective, UnFocusDirective],
})
export class ButtonModule {}
